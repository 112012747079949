import api from "./Api";

class ContractTemplateService {
  async getContractTemplateFiltered(filters?: any) {
    const customerResponse = await api.get<any[]>("contract-templates", {
      params: { filters },
    });

    return customerResponse.data;
  }
  async getContractTemplateById(id?: number) {
    console.log("---------------------");
    const { data } = await api.get<any>(`/contract-templates/${id || "0"}`);
    if (!data) return null;

    return data;
  }
}

export default new ContractTemplateService();
