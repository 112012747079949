import mammoth from "mammoth";
import RTFParser from "rtf-parser";
type AnyObject = Record<string, any>;

export class DocumentProcessor {
  private url: string;
  private tags: Record<string, string>;

  constructor(url: string, tags?: Record<string, string>) {
    this.url = url;
    if (!tags) {
      this.tags = {};
    }
  }

  // Baixa o arquivo da URL
  private async downloadFile(): Promise<Blob> {
    const response = await fetch(this.url);
    if (!response.ok) {
      throw new Error(`Erro ao baixar o arquivo: ${response.statusText}`);
    }
    return await response.blob();
  }

  // Lê o conteúdo do arquivo com base no tipo
  private async readFileContent(blob: Blob): Promise<string> {
    const fileType = this.url
      .split(".")
      .pop()
      ?.toLowerCase();

    if (fileType === "docx") {
      const result = await mammoth.extractRawText({
        arrayBuffer: await blob.arrayBuffer(),
      });
      return result.value;
    } else if (fileType === "rtf") {
      const arrayBuffer = await blob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      return new Promise((resolve, reject) => {
        RTFParser.parse(Buffer.from(uint8Array), (err, doc) => {
          if (err) reject(err);
          else {
            let textContent = "";
            doc.children?.forEach((element: any) => {
              if (element.type === "text") {
                textContent += element.value;
              }
            });
            resolve(textContent);
          }
        });
      });
    } else if (fileType === "doc") {
      throw new Error(
        "Arquivos DOC não são suportados diretamente. Converta para DOCX primeiro."
      );
    } else {
      throw new Error("Formato de arquivo não suportado.");
    }
  }

  // Substitui as tags no conteúdo pelo valor fornecido
  private replaceTags(content: string): string {
    let newContent = content;
    console.log("this.tags", this.tags);
    Object.keys(this.tags).forEach((key) => {
      const regex = new RegExp(`{${key}}`, "g");
      newContent = newContent.replace(regex, this.tags[key]);
    });
    return newContent;
  }

  // Gera um novo arquivo com o conteúdo modificado
  private generateNewFile(content: string, fileType: string): void {
    const blob = new Blob([content], { type: `application/${fileType}` });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `new_file.${fileType}`;
    a.click();
    URL.revokeObjectURL(url);
  }

  private getPropValue = (obj: AnyObject, path: string): any | undefined => {
    return path.split(".").reduce((acc, key) => acc && acc[key], obj);
  };

  public replaceTagsWithObjValues = (
    tags: AnyObject,
    obj: AnyObject
  ): AnyObject => {
    const result = { ...tags };

    for (const key in result) {
      if (result.hasOwnProperty(key) && typeof result[key] === "string") {
        const value = this.getPropValue(obj, result[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    }

    this.tags = result;
    return result;
  };
  // Método principal para processar o arquivo
  public async processDocument(): Promise<void> {
    try {
      const blob = await this.downloadFile();
      const content = await this.readFileContent(blob);
      const newContent = this.replaceTags(content);
      const fileType =
        this.url
          .split(".")
          .pop()
          ?.toLowerCase() || "rtf";
      this.generateNewFile(newContent, fileType);
    } catch (error) {
      console.error("Erro ao processar o documento:", error);
    }
  }
}

// Exemplo de uso
// const url =
//   "https://beta.milliontech.com.br/api/1740016140398-1739666026338-modelo_contrato_01.rtf";
// const tags = {
//   name: "John Doe",
//   address: "123 Main St",
//   date: "2023-10-01",
// };

// const processor = new DocumentProcessor(url, tags);
// processor.processDocument();
