import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { Route, Switch } from "react-router-dom";
import NewContractTemplate from "../pages/ContractTemplate/New-ContractTemplate";
import EditContractTemplate from "../pages/ContractTemplate/Edit-ContractTemplate";
import ListContractTemplate from "../pages/ContractTemplate/List-ContractTemplate";

export function ContractsTemplateRoutes() {
  const useSubHeader = useSubheader();
  useSubHeader.setTitle("Modelos de Contrato");

  return (
    <Switch>
      <Route
        path="/modelo-contrato/adicionar"
        component={NewContractTemplate}
      />
      <Route path="/modelo-contrato/:id" component={EditContractTemplate} />
      <Route path="/modelo-contrato" component={ListContractTemplate} />
    </Switch>
  );
}
