import React, { ReactNode, Ref, useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  OutlinedInputProps,
  Popper,
  TextField,
} from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { Button } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  popper: {
    width: "fit-content !important",
    zIndex: 9999,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type Option<T> = T | "__add__";

type ApiResourceSelectProps<T> = {
  style?: any;
  label: string;
  hasError?: boolean;
  allowClear?: boolean;
  textFieldClass?: string;
  disabled?: boolean;
  required?: boolean;
  minLengthToSearch?: number;
  noOptionsText?: string;
  getOptionLabel: (option: T) => string;
  value?: any;
  getSelectedOption?: (
    loadedRows: T[]
  ) => (T | undefined | null) | Promise<T | undefined | null>;
  onSelect: (value: T | null) => void;
  apiSearchHandler: (value: string) => Promise<T[]>;
  onChangeTextField?: (event: any) => void;
  freeSolo?: boolean;
  onInputChange?: (typedText: string) => void;
  renderAddButton?: JSX.Element | ((typedText: string) => JSX.Element);
  endAdornment?: ReactNode;
};

export default function ApiResourceSelect<T>({
  style,
  label,
  hasError,
  allowClear = true,
  textFieldClass,
  disabled,
  required,
  minLengthToSearch = 1,
  noOptionsText,
  getOptionLabel,
  value,
  getSelectedOption,
  onSelect,
  apiSearchHandler,
  onChangeTextField,
  inputRef,
  freeSolo,
  onInputChange,
  renderAddButton,
  endAdornment,
}: ApiResourceSelectProps<T> & { inputRef?: Ref<HTMLDivElement> }) {
  const classes = useStyles();

  const [foundRows, setFoundRows] = useState<Option<T>[]>([]);
  const [selectedValue, setSelectedValue] = useState<T | null | undefined>(
    value === undefined ? undefined : null
  );
  const [isSearching, setIsSearching] = useState(false);
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    async function handleGetSelectedValue() {
      if (value === undefined) {
        setSelectedValue(undefined);
      }

      if (!getSelectedOption) {
        setSelectedValue(value);
        return;
      }

      const foundValue = await getSelectedOption(
        foundRows.filter((row) => row !== "__add__") as T[]
      );

      setSelectedValue(foundValue ?? null);
    }

    handleGetSelectedValue();
  }, [value]);

  const handleInputChange = useCallback(
    async (value: any, reason: AutocompleteInputChangeReason) => {
      setTypedText("");

      if (!value) {
        setFoundRows([]);
      }

      if (reason === "input") {
        setTypedText(value);
        if (onInputChange) {
          onInputChange(value);
        }
      }

      if (reason === "reset") {
        return;
      }
      setFoundRows([]);

      if (value.length >= minLengthToSearch) {
        setIsSearching(true);
        apiSearchHandler(value).then((rows) => {
          const optionsArray: Option<T>[] = rows;

          if (renderAddButton) {
            optionsArray.push("__add__");
          }

          setIsSearching(false);
          setFoundRows(optionsArray);
        });
      }
    },
    [apiSearchHandler]
  );

  function handleGetOptionLabel(option: Option<T> | string) {
    if (option === "__add__") {
      return "";
    }

    return typeof option === "string" ? option : getOptionLabel(option) ?? "";
  }

  function handleRenderOption(option: Option<T> | string, typedText: string) {
    if (option === "__add__" && renderAddButton) {
      return typeof renderAddButton === "function"
        ? renderAddButton(typedText)
        : renderAddButton;
    }

    return <>{handleGetOptionLabel(option)}</>;
  }

  return (
    <Autocomplete
      onKeyDown={(event:any) => {
        console.log(foundRows);
        console.log(event.key);
        if (event.key === "Enter" && foundRows.length > 0) {
          event.preventDefault(); // Evita o comportamento padrão
          onSelect(foundRows[0] !== "__add__" ? foundRows[0] : null); // Seleciona o primeiro item válido

          setTimeout(() => {
            const nextElement = event.target.closest("form")?.querySelector(
              "[tabindex]:not([tabindex='-1']), input, select, textarea, button"
            );
            if (nextElement) {
              console.log("nextElement", nextElement);
              (nextElement as HTMLElement).focus();
              (nextElement as HTMLElement).focus();
            }
          }, 100);

          setTimeout(() => {
            const tabEvent = new KeyboardEvent("keydown", {
              key: "Tab",
              keyCode: 9,
              code: "Tab",
              bubbles: true,
              cancelable: true,
            });
            console.log("tabEvent", tabEvent);
            event.target.dispatchEvent(tabEvent);
            event.target.dispatchEvent(tabEvent);
          }, 100);

        }
      }}
      filterOptions={(options, state) => options}
      style={style}
      size="small"
      disableClearable={!allowClear}
      loading={isSearching}
      loadingText="Carregando..."
      noOptionsText={
        typedText && typedText.length >= minLengthToSearch
          ? noOptionsText ?? "Sem opções"
          : "Digite o início do nome para carregar os registros"
      }
      options={foundRows}
      getOptionLabel={handleGetOptionLabel}
      value={selectedValue}
      disabled={disabled}
      freeSolo={freeSolo}
      onChange={(evt, value) => value !== "__add__" && onSelect(value)}
      onInputChange={(evt, value, reason) => handleInputChange(value, reason)}
      PopperComponent={(props) => {
        return (
          <Popper
            {...props}
            className={classes.popper}
            placement="bottom-start"
          />
        );
      }}
      renderOption={(option, state) =>
        handleRenderOption(option, state.inputValue)
      }
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          label={label}
          margin="normal"
          variant="outlined"
          error={hasError}
          required={required}
          ref={inputRef}
          onChange={onChangeTextField}
          className={textFieldClass}
          title={
            selectedValue ? getOptionLabel(selectedValue) ?? "" : undefined
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment ? (
              <>
                {params.InputProps.endAdornment}

                {endAdornment}
              </>
            ) : (
              params.InputProps.endAdornment
            ),
          }}
        />
      )}
    />
  );
}
