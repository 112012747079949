import React, { useState, useEffect, useRef } from "react";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import {
  Table,
  TextField,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Button, Spinner } from "react-bootstrap";
import { formatCurrency as formatCurrencyDefault } from "../../utils/formatCurrency";

import "../../style.css";
import api from "../../services/Api";
import { Category } from "../../types/Dre";

export function ListDre() {
  const currentYear = new Date().getFullYear();

  const [isSearching, setIsSearching] = useState(true);
  const [period, setPeriod] = useState("month");
  const [year, setYear] = useState(currentYear);
  const [yearAux, setYearAux] = useState(currentYear);

  const [listPeriod, setListPeriod] = useState([]);
  const [listYear, setListYear] = useState([0]);
  const [listCategories, setListCategories] = useState<Category[]>([]);
  const [listResult, setListResult] = useState([]);
  const [canFormat, setCanFormat] = useState(true);

  const tableRef = useRef(null);

  const formatValue = (value: number) => {
    const roundedValue = Math.round(value * 100) / 100;
    return roundedValue.toFixed(2).replace(".", ",");
    // return roundedValue.toLocaleString("pr-br", {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2,
    // });
  };

  useEffect(() => {
    if (!canFormat) {
      onDownload();
      setCanFormat(true);
    }
  }, [canFormat]);

  //   const handleDownload = async () => {
  //     setCanFormat(false);
  //     await onDownload();
  //     setCanFormat(true);
  //   };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "DRE.xlsx",
    sheet: "DRE",
  });

  const formatCurrency = (value: number) => {
    return canFormat ? formatCurrencyDefault(value) : formatValue(value);
  };

  useEffect(() => {
    loadYears();
    getReport();
  }, []);

  async function getReport() {
    setIsSearching(true);

    var response = await api.post(`/dre/report`, {
      period: period,
      year: year,
      yearAux: yearAux,
    });

    setListPeriod(response.data.period);
    setListCategories(response.data.categories);
    setListResult(response.data.result);

    setIsSearching(false);
  }

  function loadYears() {
    const date = new Date();
    var yearToday = date.getFullYear();

    const years = [];
    for (let i = 2014; i <= yearToday + 2; i++) {
      years.push(i);
    }

    setListYear(years);
  }

  return (
    <div>
      <div className="card card-body pt-4">
        <div className="row mt-4">
          <div className="col-12 my-4">
            <div className="d-flex">
              <TextField
                style={{ width: "200px" }}
                size="small"
                select
                label="Periodo"
                margin="normal"
                variant="outlined"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <MenuItem key="0" value="month" selected>
                  Mensal
                </MenuItem>
                <MenuItem key="1" value="twoMonths">
                  Bimestral
                </MenuItem>
                <MenuItem key="2" value="threeMonths">
                  Trimestral
                </MenuItem>
                <MenuItem key="3" value="sixMonths">
                  Semestral
                </MenuItem>
                <MenuItem key="4" value="year">
                  Anual
                </MenuItem>
              </TextField>

              <TextField
                className="ml-4"
                style={{ width: "200px" }}
                size="small"
                select
                label="Ano"
                margin="normal"
                variant="outlined"
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
              >
                {listYear.map((yearList) => (
                  <MenuItem key={yearList} value={yearList}>
                    {yearList}
                  </MenuItem>
                ))}
              </TextField>

              {period == "year" ? (
                <TextField
                  className="ml-4"
                  style={{ width: "200px" }}
                  size="small"
                  select
                  label="Ano Final"
                  margin="normal"
                  variant="outlined"
                  value={yearAux}
                  onChange={(e) => setYearAux(Number(e.target.value))}
                >
                  {listYear.map((yearList) => (
                    <MenuItem key={yearList} value={yearList}>
                      {yearList}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                ""
              )}

              <Button
                style={{ height: "40px" }}
                type="submit"
                className="ml-3 mt-4"
                variant="primary"
                size="sm"
                onClick={() => getReport()}
                disabled={isSearching}
              >
                {isSearching ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">Aguarde...</span>
                  </>
                ) : (
                  <>
                    <span>Buscar</span>
                  </>
                )}
              </Button>

              <Button
                style={{ height: "40px" }}
                type="button"
                className="ml-3 mt-4 btn btn-info"
                size="sm"
                onClick={() => setCanFormat(false)}
              >
                Exportar Excel
              </Button>
            </div>
          </div>
          <div className="col-12 mt-4">
            <TableContainer style={{ maxHeight: "75vh" }} component={Paper}>
              <Table stickyHeader ref={tableRef}>
                <TableHead>
                  <TableRow>
                    {listPeriod.map((row) => (
                      <TableCell align="center">{row}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listCategories.map((category) => (
                    <>
                      {category.type == "revenue" ? (
                        <TableRow
                          key={category.name}
                          className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0"
                          style={{ borderColor: "green" }}
                        >
                          <TableCell>
                            <i
                              className="flaticon-add-circular-button mr-3"
                              style={{ color: "green" }}
                            ></i>
                            <b>{category.name}</b>
                          </TableCell>
                          {category.values.map((value) => (
                            <TableCell align="center">
                              <b>{formatCurrency(value)}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      ) : (
                        <TableRow
                          key={category.name}
                          className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0"
                          style={{ borderColor: "red" }}
                        >
                          <TableCell>
                            <i
                              className="flaticon-add-circular-button mr-3"
                              style={{ color: "red" }}
                            ></i>
                            <b>{category.name}</b>
                          </TableCell>
                          {category.values.map((value) => (
                            <TableCell align="center">
                              <b>{formatCurrency(value)}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      )}

                      {category.subCategories.map((subCategory) => (
                        <>
                          {category.type == "revenue" ? (
                            <TableRow
                              key={subCategory.name}
                              className="list-group-item list-group-item rounded-top border-4 border-top-0 border-right-0 border-bottom-0"
                              style={{ borderColor: "green" }}
                            >
                              <TableCell>{subCategory.name}</TableCell>
                              {subCategory.values.map((value) => (
                                <TableCell align="center">
                                  {formatCurrency(value)}
                                </TableCell>
                              ))}
                            </TableRow>
                          ) : (
                            <TableRow
                              key={subCategory.name}
                              className="list-group-item list-group-item rounded-top border-4 border-top-0 border-right-0 border-bottom-0"
                              style={{ borderColor: "red" }}
                            >
                              <TableCell>{subCategory.name}</TableCell>
                              {subCategory.values.map((value) => (
                                <TableCell align="center">
                                  {formatCurrency(value)}
                                </TableCell>
                              ))}
                            </TableRow>
                          )}
                        </>
                      ))}
                    </>
                  ))}

                  <TableRow className="list-group-item list-group-item-secondary rounded-top border-4 border-top-0 border-right-0 border-bottom-0 border-success">
                    <TableCell>
                      <b>Resultado Líquido do Exercício</b>
                    </TableCell>
                    {listResult.map((result) => (
                      <TableCell align="center">
                        <b className="d-block" style={{ whiteSpace: "nowrap" }}>
                          {formatCurrency(result)}
                        </b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
