import { NfValidationErrors } from "../components/ValidationMessageList";
import { sanitizeInput, SanitizeInputType } from "../utils/sanitizeInput";
import api from "./Api";
import CityService from "./CityService";
import CompanyService from "./CompanyService";
import CustomerService from "./CustomerService";

type NfErrors = {
  issuerErrors: NfValidationErrors[];
  customerErrors: NfValidationErrors[];
  productsErrors: NfValidationErrors[];
  transportErrors: NfValidationErrors[];
  detailsErrors: NfValidationErrors[];
};

type DataForValidations = {
  issuer: any;
  customer: any;
  customerAddress: any;
};

class NfValidationService {
  private company: any;
  private data: DataForValidations = {
    issuer: null,
    customer: null,
    customerAddress: null,
  };

  constructor(company: any) {
    this.company = company;
  }

  async getNfeValidationErrors(nfe: any): Promise<NfErrors> {
    await this.setData(nfe);

    const {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    } = await this.getCommonValidationErrors(nfe);

    // Validações do emitente
    const stateRegistrationErrors = this.validateIssuerStateRegistration();
    issuerErrors.push(...stateRegistrationErrors);

    // Validações do cliente
    const customer = this.data.customer;
    if (customer) {
      const addressErrors = this.validateCustomerAddress();
      customerErrors.push(...addressErrors);
    }

    // Validação dos detalhes da nfe
    if (!nfe.nature) {
      detailsErrors.push({
        type: "error",
        message: "Preencha a natureza da operação",
      });
    }

    const emissionDate = nfe.emissionDate
      ? new Date(nfe.emissionDate)
      : new Date();
    const outputDate = new Date(nfe.outputDate);
    if (outputDate < emissionDate) {
      detailsErrors.push({
        type: "error",
        message: "Data de saída menor que a data de emissão",
      });
    }

    return {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    };
  }

  async getNfceValidationErrors(nfce: any): Promise<NfErrors> {
    await this.setData(nfce);

    const {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    } = await this.getCommonValidationErrors(nfce);

    // Validações do emitente
    const issuer = this.company;
    const issuerCsc =
      issuer.nfEnv === "homologation"
        ? issuer.nfApiConfig.csc_nfce_homologacao
        : issuer.nfApiConfig.csc_nfce_producao;
    const issuerIdToken =
      issuer.nfEnv === "homologation"
        ? issuer.nfApiConfig.id_token_nfce_homologacao
        : issuer.nfApiConfig.id_token_nfce_producao;

    const stateRegistrationErrors = this.validateIssuerStateRegistration();
    issuerErrors.push(...stateRegistrationErrors);

    if (!issuerCsc) {
      issuerErrors.push({
        type: "error",
        message: `Preencha o CSC de ${
          issuer.nfEnv === "homologation" ? "homologação" : "produção"
        } da empresa emitente (Dados da Empresa -> Parâmetros -> NFC-e)`,
      });
    }
    if (!issuerIdToken) {
      issuerErrors.push({
        type: "error",
        message: `Preencha o ID Token de ${
          issuer.nfEnv === "homologation" ? "homologação" : "produção"
        } da empresa emitente (Dados da Empresa -> Parâmetros -> NFC-e)`,
      });
    }

    // Validação do transporte
    if (nfce.typeAttendance === 1 && nfce.modalityFreight !== 9) {
      transportErrors.push({
        type: "error",
        message:
          'O tipo de atendimento "Operação Presencial" não é compatível com a modalidade de frete selecionada',
      });
    }

    if (nfce.typeAttendance === 4 && nfce.modalityFreight === 9) {
      transportErrors.push({
        type: "error",
        message:
          'O tipo de atendimento "Operação com entrega a domicílio" não é compatível com a modalidade de frete selecionada',
      });
    }

    return {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    };
  }

  async getNfseValidationErrors(nfse: any): Promise<NfErrors> {
    await this.setData(nfse);

    const {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    } = await this.getCommonValidationErrors(nfse);

    //Validações do emitente
    const issuer = this.data.issuer;
    if (!issuer.municipalRegistration) {
      issuerErrors.push({
        type: "error",
        message: "Preencha a inscrição municipal do emitente",
      });
    }

    if (!issuer.zipCode) {
      issuerErrors.push({
        type: "error",
        message: "Preencha o CEP do emitente",
      });
    }

    if (!issuer.address) {
      issuerErrors.push({
        type: "error",
        message: "Preencha o endereço do emitente",
      });
    }
    if (!issuer.number) {
      issuerErrors.push({
        type: "error",
        message: "Preencha o número do emitente",
      });
    }

    if (!issuer.district) {
      issuerErrors.push({
        type: "error",
        message: "Preencha o bairro do emitente",
      });
    }

    if (!issuer.city) {
      issuerErrors.push({
        type: "error",
        message: "Preencha a cidade do emitente",
      });
    }

    if (!issuer.state) {
      issuerErrors.push({
        type: "error",
        message: "Preencha o estado do emitente",
      });
    }

    //Validação do cliente
    const addressErrors = this.validateCustomerAddress();
    customerErrors.push(...addressErrors);

    const issuerCityObj = await CityService.getCityByName(issuer.city || "");
    const issuerCityCode = issuerCityObj?.code;

    //Validação detalhes da nfse
    let placeDeliveryCityCode = null;
    if (nfse.placeDelivery === "endereço do emitente") {
      placeDeliveryCityCode = issuerCityCode;
    } else if (nfse.placeDelivery === "endereço do destinatario") {
      const cityObj = await CityService.getCityByName(
        this.data.customerAddress?.city || ""
      );
      placeDeliveryCityCode = cityObj?.code;
    } else {
      placeDeliveryCityCode = nfse.cityDelivery
        ? Number(nfse.cityDelivery)
        : null;
    }

    if (issuerCityCode && placeDeliveryCityCode) {
      if (nfse.nature === 2 && issuerCityCode === placeDeliveryCityCode) {
        issuerErrors.push({
          type: "error",
          message:
            'A natureza da operação selecionada é "Tributação fora do município", porém empresa emitente e o local de prestação estão localizados na mesma cidade',
        });
      }
      if (nfse.nature === 1 && issuerCityCode !== placeDeliveryCityCode) {
        issuerErrors.push({
          type: "error",
          message:
            'A natureza da operação selecionada é "Tributação no município", porém empresa emitente e o local de prestação estão localizados em cidades diferentes',
        });
      }
    }

    if (!placeDeliveryCityCode) {
      issuerErrors.push({
        type: "error",
        message: "Local de prestação do serviço é inválido",
      });
    }

    return {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    };
  }

  /**
   * Validações comuns a todos os tipos de notas fiscais
   */
  private async getCommonValidationErrors(nf: any): Promise<NfErrors> {
    const issuerErrors: NfValidationErrors[] = [];
    const customerErrors: NfValidationErrors[] = [];
    const productsErrors: NfValidationErrors[] = [];
    const transportErrors: NfValidationErrors[] = [];
    const detailsErrors: NfValidationErrors[] = [];

    // Validação do emitente
    const issuer = this.data.issuer;
    const issuerStateRegistration = issuer.stateRegistration || "ISENTO";
    const stateRegistrationPattern = /[0-9]{2,14}|ISENTO/;

    if (!stateRegistrationPattern.test(issuerStateRegistration)) {
      issuerErrors.push({
        type: "error",
        message:
          'A inscrição estadual da empresa emitente deve ter entre 2 e 14 dígitos, ou ser igual à "ISENTO"',
      });
    }

    // Validação do cliente
    const customer = this.data.customer;
    if (customer) {
      if (
        customer.stateRegistration &&
        customer.stateRegistration !== "ISENTO" &&
        customer.stateRegistration.length < 2
      ) {
        customerErrors.push({
          type: "error",
          message:
            "A inscrição estadual do cliente deve ter 2 ou mais caracteres",
        });
      }

      if (
        customer.icmsTaxpayer === "y" &&
        customer.exempt === "n" &&
        !customer.stateRegistration
      ) {
        customerErrors.push({
          type: "error",
          message:
            "Cliente contribuinte do ICMS deve ter a inscrição estadual preenchida ou marcado como isento",
        });
      }

      if (
        nf.destinationOperation === 3 &&
        (customer.isForeign === "n" || !customer.passport)
      ) {
        customerErrors.push({
          type: "error",
          message:
            "Para nota fiscal no exterior, é necessário que o cliente possua o passaporte estrangeiro preenchido",
        });
      }
    }

    //Validação dos produtos
    const nfProducts = nf.products ? (JSON.parse(nf.products) as any[]) : [];

    for (const nfProduct of nfProducts) {
      const { data: product } = await api.get(
        `products/${nfProduct.INSTANCE.id ?? "0"}`
      );
      if (!product) {
        productsErrors.push({
          type: "error",
          message: `Produto "${nfProduct.INSTANCE.name ||
            nfProduct.name}": Cadastro não encontrado`,
        });
      }

      const cfopPattern = /[1,2,3,5,6,7]{1}[0-9]{3}/;
      const productCfop = sanitizeInput(
        SanitizeInputType.NUMERIC,
        nfProduct.cfop.trim()
      );
      if (!productCfop || productCfop == "0") {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": Preencha o CFOP do produto`,
        });
      }
      if (productCfop.length !== 4) {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": O CFOP do produto precisa ter 4 caracteres`,
        });
      }
      if (!cfopPattern.test(productCfop)) {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": O CFOP informado não é válido`,
        });
      }

      const productNcm =
        nfProduct.ncm.trim() && nfProduct.ncm.trim() != "0"
          ? nfProduct.ncm.trim()
          : product.ncm;
      const sanitizedNcm = sanitizeInput(
        SanitizeInputType.NUMERIC,
        productNcm ?? ""
      );
      if (!sanitizedNcm) {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": Preencha o NCM do produto`,
        });
      }
      if (sanitizedNcm.length !== 8) {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": O NCM do produto precisa ter 8 caracteres`,
        });
      }

      if (!product.productOrigin) {
        productsErrors.push({
          type: "error",
          message: `Produto "${product.name}": Preencha a origem do produto`,
        });
      }
    }

    return {
      issuerErrors,
      customerErrors,
      productsErrors,
      transportErrors,
      detailsErrors,
    };
  }

  private validateIssuerStateRegistration(): NfValidationErrors[] {
    const issuerErrors: NfValidationErrors[] = [];

    const issuer = this.data.issuer;
    const issuerStateRegistration = issuer.stateRegistration || "ISENTO";
    const stateRegistrationPattern = /[0-9]{2,14}|ISENTO/;

    if (!stateRegistrationPattern.test(issuerStateRegistration)) {
      issuerErrors.push({
        type: "error",
        message:
          'A inscrição estadual da empresa emitente deve ter entre 2 e 14 dígitos, ou ser igual à "ISENTO"',
      });
    }

    return issuerErrors;
  }

  private validateCustomerAddress(): NfValidationErrors[] {
    const customerErrors: NfValidationErrors[] = [];
    const customerAddress = this.data.customerAddress;

    if (!customerAddress?.zipcode) {
      customerErrors.push({
        type: "error",
        message: "Preencha o CEP do cliente",
      });
    }

    if (!customerAddress?.publicPlace) {
      customerErrors.push({
        type: "error",
        message: "Preencha o endereço do cliente",
      });
    }
    if (!customerAddress?.number) {
      customerErrors.push({
        type: "error",
        message: "Preencha o número do cliente",
      });
    }

    if (!customerAddress?.district) {
      customerErrors.push({
        type: "error",
        message: "Preencha o bairro do cliente",
      });
    }

    if (!customerAddress?.city) {
      customerErrors.push({
        type: "error",
        message: "Preencha a cidade do cliente",
      });
    }

    if (!customerAddress?.state) {
      customerErrors.push({
        type: "error",
        message: "Preencha o estado do cliente",
      });
    }

    return customerErrors;
  }

  private async setData(nf: any): Promise<void> {
    let issuer = this.company;
    const customer = await CustomerService.getCustomerById(nf.customer);
    let customerAddress = null;

    if (customer) {
      const { data } = await api.get(`/address/user/default/${customer.id}`);
      customerAddress = data ?? null;
    }

    if (!issuer.nfApiConfig) {
      issuer = await CompanyService.getCompanyById(issuer.id, {
        withNfApiConfig: true,
      });
    }

    this.data = {
      issuer,
      customer,
      customerAddress,
    };
  }
}

export default NfValidationService;
