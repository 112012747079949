/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Badge } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import useCompany from "../../../../../app/hooks/company";
import { useCompanyBranch } from "../../../../../app/hooks/companyBranch";
import useMenu, { Menus } from "../../../../../app/hooks/menu";
import useNotification from "../../../../../app/hooks/notification";
import CompanyService from "../../../../../app/services/CompanyService";
import { formatDate } from "../../../../../app/utils/dateFormat";
import { getDate } from "../../../../../app/utils/dateTimeHelper";
import { checkIsActive } from "../../../../_helpers";
import "../../../../../app/style.css";
import { limitString } from "../../../../../app/utils/limitString";
import api from "../../../../../app/services/Api";
import * as auth from "../../../../../app/modules/Auth";
import "../../../../css/HeaderMenu.css";
import apiBFF from "../../../../../app/services/ApiBFF";
import { generateRandomPassword } from "../../../../../app/utils/password";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { menus } = useMenu();
  const { companies, companyCanHaveBranches } = useCompany({
    only: user?.companyId,
    appendSubscriptionPlan: true,
  });

  const [millionZapPlanId, setMillionZapPlanId] = useState();
  const [millionZapPasswordHash, setMillionZapPasswordHash] = useState();
  const [millionZapCompanyData, setMillionZapCompanyData] = useState();
  const { selectedCompany } = useCompanyBranch();

  useEffect(() => {
    try {
      const company = companies[0];
      if (millionZapPlanId && company?.email && !millionZapPasswordHash)
        handleMillionZapPasswordHash(company?.email);
    } catch (error) {
      console.log(error);
    }
  }, [millionZapPlanId]);

  useEffect(() => {
    try {
      const company = companies[0];
      if (company && user) {
        if (!millionZapPlanId) {
          setMillionZapPlanId(
            company?.plan?.subscriptionPlan?.millionZapPlanId
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [companies, user]);

  const handleMillionZapPasswordHash = async (email) => {
    try {
      const { data } = await apiBFF.get(`millionzap/user/hash/${email}`);
      if (data) setMillionZapPasswordHash(data?.passwordHash);
    } catch (error) {
      console.log(error);
    }
  };

  const getMillionZapData = async ({ email }) => {
    
    try {
      
    const company = companies[0];
    if (!company) return;


    const response = await apiBFF.get(
      `millionzap/company?searchParam=${email}&fieldName=email`
    );

    const millionZapCompany = response?.data?.companies[0];
    if (!millionZapCompany) {
      try {
        const currentDate = new Date();
        const dueDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        );
        const newCompanyData = {
          name: company.name,
          email: company.email,
          phone: company.phone.replace(/\D/g, ""),
          password: generateRandomPassword(),
          planId: company.plan.subscriptionPlan.millionZapPlanId,
          recurrence: "MENSAL",
          dueDate,
          status: "t",
          campaignsEnabled: true,
        };

        await apiBFF.post(`millionzap/company/create`, newCompanyData);

        await handleMillionZapPasswordHash(company.email);
      } catch (error) {
        console.log("Erro na integração com millionzap: ", error);
      }
    }
    setMillionZapCompanyData(true);
    } catch (error) {
      console.log(error);
    }
    
  };

  useEffect(() => {
    try {
      const company = companies[0];

      if (!millionZapCompanyData && company) {
        getMillionZapData({ email: company.email });
      }
    } catch (error) {
      console.log(error);
    }
  }, [millionZapPlanId]);

  const {
    notifications,
    countNotifications,
    handleDismissNotification,
  } = useNotification();
  const [mainCompany] = companies;

  const dispatch = useDispatch();

  const userPermissions = user ? JSON.parse(user.permissions) : [];

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const menuShowConditions = useCallback(
    (menu) => {
      if (user.isAccountant == "y") {
        var permissionAccountant = userPermissions.find(
          (permission) => permission.companyId == selectedCompany?.id
        )?.permissions;

        if (permissionAccountant) {
          return (
            permissionAccountant.includes(menu.id) ||
            (user.isAdmin === "y" && menu.route?.id === 1)
          );
        }
      } else {
        return (
          userPermissions.includes(menu.id) ||
          (user.isAdmin === "y" && menu.route?.id === 1)
        );
      }
    },
    [selectedCompany]
  );

  const subMenuShowConditions = useCallback(
    (menu) => {
      let canShow = false;

      var permissionAccountant =
        user.isAccountant == "y"
          ? userPermissions.find(
              (permission) => permission.companyId == selectedCompany?.id
            ).permissions
          : [];

      if (user.isAccountant == "n" && userPermissions.includes(menu.id)) {
        canShow = true;
      } else if (
        user.isAccountant == "y" &&
        permissionAccountant.includes(menu.id)
      ) {
        canShow = true;
      }

      if (user.isAdmin === "y" && menu.route?.startsWith("/usuarios")) {
        canShow = true;
      }

      if (
        mainCompany &&
        !companyCanHaveBranches(mainCompany) &&
        menu.id === Menus.BRANCHES &&
        CompanyService.isMainCompany(selectedCompany)
      ) {
        canShow = false;
      }

      return canShow;
    },
    [selectedCompany]
  );

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {user.isSuper === "y" && (
          <>
            {/*begin::1 Level*/}
            <li
              data-menu-toggle={layoutProps.menuDesktopToggle}
              aria-haspopup="true"
              className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                "/google-material"
              )}`}
            >
              <NavLink className="menu-link menu-toggle" to="/google-material">
                <span className="menu-text">SUPER</span>
                <i className="menu-arrow"></i>
              </NavLink>
              <div
                className="menu-submenu menu-submenu-classic menu-submenu-left"
                style={{
                  width: "100vw",
                  position: "fixed",
                  left: 0,
                  top: 60,
                  zIndex: 99,
                }}
              >
                <ul className="menu-subnav">
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/empresas"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/empresas">
                      <span className="menu-text">Empresas</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/planos"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/planos">
                      <span className="menu-text">Planos</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/perfis-acesso"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/perfis-acesso">
                      <span className="menu-text">Perfis de Acesso</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/faturas/super"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/faturas/super">
                      <span className="menu-text">Faturas</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/config/super"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/config/super">
                      <span className="menu-text">Configurações</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          </>
        )}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive("/painel")}`}
        >
          <NavLink className="menu-link" to="/painel">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {menus.length > 0 &&
          menus
            .filter((menu) => menu.name !== "Dashboard")
            .map(
              (menu) =>
                menuShowConditions(menu) && (
                  <li
                    data-menu-toggle={
                      menu.submenus?.length && layoutProps.menuDesktopToggle
                    }
                    aria-haspopup={menu.submenus?.length && "true"}
                    key={menu.id}
                    className={`menu-item ${menu.submenus?.length &&
                      "menu-item-submenu"} menu-item-rel ${getMenuItemActive(
                      menu.route ?? "/toggle"
                    )}`}
                  >
                    <NavLink
                      className={`menu-link ${menu.submenus?.length &&
                        "menu-toggle"}`}
                      to={menu.route ?? "/toggle"}
                    >
                      <span className="menu-text">{menu.name}</span>
                      {menu.submenus?.length && <i className="menu-arrow"></i>}
                    </NavLink>
                    {menu.submenus?.length && (
                      <div
                      className="menu-submenu menu-submenu-classic menu-submenu-left"
                      style={{
                        width: "100vw",
                        position: "fixed",
                        left: 0,
                        top: 60,
                        zIndex: 99,
                        background: "#fff",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        padding: "10px 0",
                      }}
                    >
                      <ul
                        className="menu-subnav"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          gap: "16px",
                          padding: "10px",
                        }}
                      >
                        {menu.submenus.map(
                          (submenu1) =>
                            subMenuShowConditions(submenu1) && (
                              <li
                                key={submenu1.id}
                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                  submenu1.route ?? "/toggle/sub"
                                )}`}
                                data-menu-toggle="hover"
                                aria-haspopup="true"
                              >
                                <NavLink
                                  className={`menu-link ${submenu1.submenus?.length && "menu-toggle"}`}
                                  to={submenu1.route ?? "/toggle/sub"}
                                >
                                  <span className="menu-text">{submenu1.name}</span>
                                  {submenu1.submenus?.length && <i className="menu-arrow"></i>}
                                </NavLink>
                                {submenu1.submenus?.length && (
                                  <div
                                    className={`menu-submenu menu-submenu-classic menu-submenu-right`}
                                    style={{
                                      width: "230px",
                                      maxHeight: "300px",
                                      overflowY: "auto",
                                      background: "#fff",
                                      borderRadius: "6px",
                                      padding: "8px",
                                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <ul className="menu-subnav" style={{ flexDirection: "column" }}>
                                      {submenu1.submenus.map(
                                        (submenu2) =>
                                          (user.isAccountant == "y"
                                            ? JSON.parse(
                                                userPermissions.find(
                                                  (permission) => permission.companyId == selectedCompany?.id
                                                ).permissions
                                              ).includes(submenu2.id)
                                            : userPermissions.includes(submenu2.id)) && (
                                            <li
                                              key={submenu2.id}
                                              className={`menu-item ${getMenuItemActive(
                                                submenu2.route ?? "/toggle/sub"
                                              )}`}
                                            >
                                              <NavLink
                                                className="menu-link"
                                                to={submenu2.route ?? "/toggle/sub"}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  padding: "10px 12px",
                                                  borderRadius: "4px",
                                                  transition: "background 0.2s",
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.background = "#f5f5f5")}
                                                onMouseLeave={(e) => (e.currentTarget.style.background = "transparent")}
                                              >
                                                <i className="menu-bullet menu-bullet-dot">
                                                  <span />
                                                </i>
                                                <span className="menu-text">{submenu2.name}</span>
                                              </NavLink>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  </div>                  
                                )}
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                    )}
                  </li>
                )
            )}

        {user.isAccountant == "y" ? (
          <li
            data-menu-toggle={"click"}
            aria-haspopup={"true"}
            // key={menu.id}
            className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
              "/toggle"
            )}`}
          >
            <NavLink className={`menu-link menu-toggle`} to={"/toggle"}>
              <span className="menu-text">Contábil</span>
            </NavLink>
            <div
              className="menu-submenu menu-submenu-classic menu-submenu-left"
              style={{
                width: "100vw",
                position: "fixed",
                left: 0,
                top: 60,
                zIndex: 99,
              }}
            >
              <ul className="menu-subnav">
                <li
                  key={0}
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/contabil"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className={`menu-link`} to={"/contabil"}>
                    <span className="menu-text">Relatório Contábil</span>
                  </NavLink>
                </li>
                <li
                  key={1}
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/indicacoes"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className={`menu-link`} to={"/indicacoes"}>
                    <span className="menu-text">Indicações</span>
                  </NavLink>
                </li>
                <li
                  key={2}
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/contador/empresas"
                  )}`}
                  data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className={`menu-link`} to={"/contador/empresas"}>
                    <span className="menu-text">Empresas</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
        ) : (
          <></>
        )}

        {millionZapPlanId > 0 && (
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-subnav menu-item-rel`}
          >
            <a
              className="menu-link"
              href={`${process.env.REACT_APP_MILLIONZAP_APP_URL}/login/${companies[0]?.email}/${millionZapPasswordHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="menu-text">MillionZap</span>
            </a>
          </li>
        )}

        {
          <li
            data-menu-toggle={layoutProps.menuDesktopToggle}
            aria-haspopup="true"
            className={`menu-item menu-item-submenu menu-subnav menu-item-rel`}
          >
            <NavLink
              className="menu-link menu-toggle"
              to="submenu1.route ?? '/toggle/sub"
            >
              <Badge
                badgeContent={countNotifications}
                style={{
                  color: "white",
                  // borderRadius: "50%",
                  // backgroundColor: "white",
                }}
              >
                <i
                  className="mb-0 flaticon2-bell-2"
                  style={{ fontSize: "20px", color: "#eee" }}
                ></i>
              </Badge>
              <i className="menu-arrow"></i>
            </NavLink>

            <div
              className={`menu-submenu menu-submenu-classic menu-submenu-right`}
              style={{
                width: "350px",
                maxHeight: "65vh",
                overflow: "auto",
                zIndex: 99,
              }}
            >
              <ul
                className="menu-subnav"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {notifications.length > 0 &&
                  notifications.map((notification) => (
                    <li
                      className={`menu-item menu-item-submenu menu-subnav menu-item-rel`}
                      key={notification.id}
                      style={{ maxWidth: "100%" }}
                    >
                      <div className="w-100 px-5 py-3 d-flex align-items-start justify-content-between hover">
                        <NavLink
                          className="menu-link d-flex"
                          to={notification.link}
                        >
                          <i className="mr-4 flaticon2-warning "></i>

                          <div className="d-flex flex-column">
                            <p className="ml-2 mb-0 text-body">
                              {notification.title}
                            </p>

                            <small
                              className="ml-2 mt-1 mb-0 text-body"
                              style={{ fontSize: "11px" }}
                            >
                              {limitString(
                                notification.message.substr(
                                  notification.message.indexOf(", Usuário") + 2
                                ),
                                120
                              )}
                            </small>

                            <span className="mt-2 ml-4 text-muted">
                              {
                                getDate({
                                  initialDate: new Date(notification.updatedAt),
                                }).fullDateStr
                              }
                            </span>
                          </div>
                        </NavLink>

                        <div className="ml-2">
                          <CloseButton
                            onClick={(e) =>
                              handleDismissNotification(notification.id)
                            }
                          />
                        </div>
                      </div>
                    </li>
                  ))}

                {countNotifications <= 0 && (
                  <div className="d-flex text-align-center justify-content-center">
                    <strong className="text-center" style={{ color: "#ccc" }}>
                      Nenhuma notificação no momento
                    </strong>
                  </div>
                )}

                {countNotifications > 5 && (
                  <NavLink
                    className="float-right ml-4 mt-5"
                    to={`/notificacoes`}
                  >
                    <p className="mb-0 ">Ver Mais</p>
                  </NavLink>
                )}
              </ul>
            </div>
          </li>
        }
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
