import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import api from "../../services/Api";
import { getDate, getNameMonth } from "../../utils/dateTimeHelper";
import {
  formatCurrency,
  formatIntegerToMoney,
  formatToFloat,
} from "../../utils/formatCurrency";
import {
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import {
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Zoom,
  Tooltip as TooltipMaterialUi,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import useCompany from "../../hooks/company";
import { Button, ButtonGroup } from "react-bootstrap";
import { Calendar } from "./Calendar";
import { PdvSale } from "../../types/PdvSale";
import { PdvSalePayment } from "../../types/PdvSalePayment";
import { useCompanyBranch } from "../../hooks/companyBranch";
import useCurrentBalance from "../../hooks/useCurrentBalance";
import CashFlowService from "../../services/CashFlowService";
import {
  BankAccountsTotalsPieChart,
  totalAccounts,
} from "../../components/BankAccountsTotalsPieChart";
import {
  cashFlow,
  CashFlowComposedChart,
} from "../../components/CashFlowComposedChart";
import useMenu from "../../hooks/menu";
import { PermissionMenu } from "../../types/PermissionMenu";
import { differenceInDays } from "date-fns";
import { useToast } from "../../utils/toastUtils";
import { formatDate } from "../../utils/dateFormat";
import { useWebSocket } from "../../hooks/useWebSocket";

type sells = {
  name: string;
  vendas: number;
};

type invoicing = {
  id: number;
  name: string;
  value: number;
};

type menuSystem = {
  id: number;
  name: string;
  route: string;
  visible: string;
  menuId: number;
  order: number;
};

type pdv = {
  id: number;
  active: string;
  userId: number;
  operator: string;
  companyId: string;
  createdDate: string;
  openedDate: string;
  startValue: number;
  currentValue: number;
  limitValue: number;
  totalCashier: number;

  alert: boolean;
  companyName: string;
};

type ranking = {
  id: number;
  name: string;
  value: number;
};

export function DashboardPage() {
  const { user } = useSelector((state: any) => state.auth);
  const { menus } = useMenu();
  const [savedPermissions, setSavedPermissions] = useState<number[]>();
  const [companyplan, setCompanyPlan] = useState<any>();
  const { mainAndBranches } = useCompany({ mainCompanyId: user.companyId });
  const { selectedCompany: currentCompany } = useCompanyBranch();

  // Botões de rápido acesso
  const [modulesSystem, setModulesSystem] = useState<menuSystem[]>([]);
  const [fastAccess, setFastAccess] = useState<number[]>([]);

  // Data da Dashboard
  const [typeShowDashboard, setTypeShowDashboard] = useState("month");
  const [monthDashboard, setMonthDashboard] = useState(
    new Date().getMonth() + 1
  );
  const [yearsAvailable, setYearsAvailable] = useState<Number[]>([]);
  const [yearDashboard, setYearDashboard] = useState(new Date().getFullYear());
  const [companyDashboard, setCompanyDashboard] = useState("0");

  // A Receber Hoje  |   Recebimentos em Atraso
  const [todayReceive, setTodayReceive] = useState(0);
  const [todayReceiveLate, setTodayReceiveLate] = useState(0);

  // A Pagar Hoje    |   Pagamentos em Atraso
  const [todayPay, setTodayPay] = useState(0);
  const [todayPayLate, setTodayPayLate] = useState(0);

  // Saldo Total Por Conta
  const [activeData, setActiveData] = useState(0);
  const [totalAccounts, setTotalAccounts] = useState<totalAccounts[]>([]);
  const [totalValueAccounts, setTotalValueAccounts] = useState(0);
  const {
    totalBalance,
    getTotalBalance,
    getAccountBalance,
  } = useCurrentBalance(companyDashboard);

  // Fluxo de Caixa
  const [cashFlow, setCashFlow] = useState<cashFlow[]>([]);
  const [cashReceive, setCashReceive] = useState(0);
  const [cashPay, setCashPay] = useState(0);
  const [typeShow, setTypeShow] = useState("foreseen");

  // Vendas
  const [sells, setSells] = useState<sells[]>([]);
  const [totalSells, setTotalSells] = useState(0);
  const [ticketAverage, setTicketAverage] = useState(0);

  // Saldos PDVs
  const [balancePdvs, setBalancePdvs] = useState<pdv[]>([]);
  const [totalPdvs, setTotalPdvs] = useState(0);

  // Calendário de Lançamentos e Eventos
  const [dateSelected, setDateSelected] = useState(
    getDate({ initialDate: new Date() }).dateStr
  );

  // Faturamento por Cliente
  const [billingPerCustomers, setBillingPerCustomers] = useState<invoicing[]>(
    []
  );

  // Faturamento por Produto
  const [billingPerProductsServices, setBillingPerProductsServices] = useState<
    invoicing[]
  >([]);

  // Ranking Despesas
  const [rankingExpenses, setRankingExpenses] = useState<ranking[]>([]);

  // Ranking Receitas
  const [rankingRevenues, setRankingRevenues] = useState<ranking[]>([]);

  const AntSwitch = withStyles((theme: any) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const { showToast, ToastElement } = useToast();

  const checkDate = (dateStr: string) => {
    const differenceDays = differenceInDays(new Date(dateStr), new Date());
    if (differenceDays <= 4 && differenceDays >= 0) {
      showToast({
        header: "Aviso",
        message: `Faltam ${Math.ceil(
          differenceDays
        )} dias para o vencimento do seu plano. Vencimento em ${formatDate(
          dateStr
        )}`,
        type: "warning",
        duration: 30,
      });
    }
  };

  const getSubscriptionPlan = useCallback(async () => {
    const response = await api.get(
      `companies/${user.companyId}/subscriptionPlan/active`
    );

    checkDate(response?.data?.expirationDate);
    setCompanyPlan(response.data);
  }, [user]);

  useLayoutEffect(() => {
    getYearsAvailable();
  }, []);

  useEffect(() => {
    getSubscriptionPlan();
  }, [getSubscriptionPlan]);

  useEffect(() => {
    setFastAccess(JSON.parse(user.fastAccess) ?? []);

    getModulesSystem();
    getTodayReceive();
    getTodayPay();
  }, [currentCompany]);

  const loadAll = async () => {
    setTimeout(async () => {
      await getTodayReceiveLate();
    }, 500);
    setTimeout(async () => {
      await getTodayPayLate();
    }, 1000);
    setTimeout(async () => {
      await getCashFlow();
    }, 1500);
    setTimeout(async () => {
      await getSells();
    }, 2000);
    setTimeout(async () => {
      await getPdv();
    }, 2500);
    setTimeout(async () => {
      await getBillings();
    }, 3000);
    setTimeout(async () => {
      await getTotalAccounts();
    }, 3500);
    setTimeout(async () => {
      await getRankingExpenses();
    }, 4000);
    setTimeout(async () => {
      await getRankingRevenues();
    }, 4500);
  };

  useEffect(() => {
    loadAll();
  }, [
    typeShow,
    typeShowDashboard,
    monthDashboard,
    yearDashboard,
    companyDashboard,
    currentCompany,
  ]);

  const styleToolTip = {
    background: "#fff",
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "5px",
    outline: "none",
  };

  const renderTooltipSells = (element: any) => {
    var month = monthDashboard < 10 ? "0" + monthDashboard : monthDashboard;

    return (
      <div style={styleToolTip}>
        <b>
          {typeShowDashboard == "month"
            ? element.label + "/" + month + "/" + yearDashboard
            : element.label + " De " + yearDashboard}
        </b>
        <hr style={{ margin: "5px" }} />

        {element.payload &&
          element.payload.map((data: any) => {
            return (
              <p style={{ color: data.color }}>
                <b>{data.name.charAt(0).toUpperCase() + data.name.slice(1)}:</b>{" "}
                {formatCurrency(data.value)}
              </p>
            );
          })}
      </div>
    );
  };

  function mapPermissionsChecked(
    permissions: Array<number>,
    menus: PermissionMenu[]
  ) {
    const aux = menus;
    for (let i = 0; i < aux.length; i++) {
      aux[i].checked = permissions.includes(aux[i].id);
      if (aux[i].submenus?.length) {
        aux[i].submenus = mapPermissionsChecked(
          permissions,
          aux[i].submenus as PermissionMenu[]
        );
      }
    }
    return aux;
  }

  async function getYearsAvailable() {
    var toReceive = await api.post("/billsToReceive/config-dashboard");
    var toPay = await api.post("/billsToPay/config-dashboard");
    var requests = await api.get("/requests/config-dashboard");
    var serviceOrders = await api.post("/service-order/config/dashboard");
    var pdvSales = await api.post(`/pdvSales/config-dashboard`);

    var years: Number[] = [];
    for (var receive of toReceive.data) {
      var date = receive.dueDate.split("-");

      if (!years.includes(Number(date[0]))) {
        years.push(Number(date[0]));
      }
    }

    for (var pay of toPay.data) {
      var date = pay.dueDate.split("-");

      if (!years.includes(Number(date[0]))) {
        years.push(Number(date[0]));
      }
    }

    for (var request of requests.data) {
      var date = request.requestDate.split("-");

      if (!years.includes(Number(date[0]))) {
        years.push(Number(date[0]));
      }
    }

    for (var serviceOrder of serviceOrders.data) {
      var date = serviceOrder.date.split("-");

      if (!years.includes(Number(date[0]))) {
        years.push(Number(date[0]));
      }
    }

    for (var sale of pdvSales.data) {
      var dateSale = new Date(sale.createdDate);
      if (!years.includes(Number(dateSale.getFullYear()))) {
        years.push(Number(dateSale.getFullYear()));
      }
    }

    years.sort(function(year1: any, year2: any) {
      return year1 - year2;
    });

    if (!years.length) {
      years.push(new Date().getFullYear());
    }

    if (!years.includes(new Date().getFullYear())) {
      years.push(new Date().getFullYear());
    }

    setYearsAvailable(years);
  }

  async function getModulesSystem() {
    const modules = await api.get("/menus");

    const modulesSorted = modules.data.sort((data1: any, data2: any) => {
      return data1.id - data2.id;
    });

    setModulesSystem(modulesSorted);
  }

  async function getTodayReceive() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) =>
              submenu.name == "A Receber Hoje / Recebimentos em Atraso"
          )[0].id
      )
    ) {
      var bills =
        companyDashboard != "0"
          ? await api.post(
              `/billsToReceive/config-dashboard/${companyDashboard}`
            )
          : await api.post("/billsToReceive/config-dashboard");
      var today = getDate({ initialDate: new Date() }).dateStr;
      today = today
        .substr(0, 10)
        .split("/")
        .reverse()
        .join("-");

      var total = 0;
      for (var bill of bills.data) {
        if (bill.dueDate == today && bill.status != "paid") {
          total += bill.amount;
        }
      }

      setTodayReceive(total);
    }
  }

  async function getTodayPay() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "A Pagar Hoje / Pagamentos em Atraso"
          )[0].id
      )
    ) {
      var bills =
        companyDashboard != "0"
          ? await api.post(`/billsToPay/config-dashboard/${companyDashboard}`)
          : await api.post("/billsToPay/config-dashboard");
      var today = getDate({ initialDate: new Date() }).dateStr;
      today = today
        .substr(0, 10)
        .split("/")
        .reverse()
        .join("-");

      var total = 0;
      for (var bill of bills.data) {
        if (bill.dueDate == today && bill.status != "paid") {
          total += bill.amount;
        }
      }

      setTodayPay(total);
    }
  }

  async function getTodayReceiveLate() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) =>
              submenu.name == "A Receber Hoje / Recebimentos em Atraso"
          )[0].id
      )
    ) {
      var bills =
        companyDashboard != "0"
          ? await api.post(
              `/billsToReceive/config-dashboard/${companyDashboard}`
            )
          : await api.post(`/billsToReceive/config-dashboard`);

      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var finalDate =
        typeShowDashboard == "month"
          ? new Date(
              yearDashboard == 0 ? today.getFullYear() : yearDashboard,
              monthDashboard == 0 ? today.getMonth() : monthDashboard,
              0
            )
          : new Date(
              yearDashboard == 0 ? today.getFullYear() : yearDashboard,
              12,
              0
            );

      var total = 0;

      var count = 0;
      for (var bill of bills.data) {
        var dueDateSplit = bill.dueDate.split("-");
        var dueDate = new Date(
          Number(dueDateSplit[0]),
          Number(dueDateSplit[1]) - 1,
          Number(dueDateSplit[2])
        );
        dueDate.setHours(0, 0, 0, 0);

        if (dueDate < today && dueDate < finalDate && bill.status != "paid") {
          total += bill.amount;
          count += 1;
        }
      }
      setTodayReceiveLate(total);
    }
  }

  async function getTodayPayLate() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "A Pagar Hoje / Pagamentos em Atraso"
          )[0].id
      )
    ) {
      var bills =
        companyDashboard != "0"
          ? await api.post(`/billsToPay/config-dashboard/${companyDashboard}`)
          : await api.post(`/billsToPay/config-dashboard`);

      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var finalDate =
        typeShowDashboard == "month"
          ? new Date(
              yearDashboard == 0 ? today.getFullYear() : yearDashboard,
              monthDashboard == 0 ? today.getMonth() : monthDashboard,
              0
            )
          : new Date(
              yearDashboard == 0 ? today.getFullYear() : yearDashboard,
              12,
              0
            );

      var total = 0;
      for (var bill of bills.data) {
        var dueDateSplit = bill.dueDate.split("-");
        var dueDate = new Date(
          Number(dueDateSplit[0]),
          Number(dueDateSplit[1]) - 1,
          Number(dueDateSplit[2])
        );
        dueDate.setHours(0, 0, 0, 0);

        if (dueDate < today && dueDate < finalDate && bill.status != "paid") {
          total += bill.amount;
        }
      }

      setTodayPayLate(total);
    }
  }

  async function getTotalAccounts() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "Saldo Total Por Conta"
          )[0].id
      )
    ) {
      var result = await getTotalBalance();
      setTotalAccounts(result.objects);
    }
  }

  async function getCashFlow() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter((submenu) => submenu.name == "Fluxo de Caixa")[0]
          .id
      )
    ) {
      const accountBanks = await api.get("/accountsBank");
      const today = new Date();

      if (typeShowDashboard == "month") {
        var initialDate = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            monthDashboard == 0 ? today.getMonth() : monthDashboard - 1
          ),
        }).dateStr;
        var finalDate = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            monthDashboard == 0 ? today.getMonth() + 1 : monthDashboard,
            0
          ),
        }).dateStr;
      } else {
        var initialDate = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            0
          ),
        }).dateStr;
        var finalDate = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            12,
            0
          ),
        }).dateStr;
      }

      var bills = await CashFlowService.getBills({
        accountBank: "",
        initialDate: initialDate
          .split("/")
          .reverse()
          .join("-"),
        finalDate: finalDate
          .split("/")
          .reverse()
          .join("-"),
        foreseen: typeShow == "foreseen" ? true : false,
        accomplished: typeShow == "accomplished" ? true : false,
      });
      const totals = CashFlowService.getTotalAccount(bills, accountBanks.data);

      let cashFlowArr: cashFlow[] = [];

      if (typeShowDashboard === "month") {
        cashFlowArr = CashFlowService.getCashFlowCompositeChartData({
          bills,
          dateType: "month",
          currentYear: yearDashboard,
          currentMonth: monthDashboard,
        });
      } else {
        cashFlowArr = CashFlowService.getCashFlowCompositeChartData({
          bills,
          dateType: "year",
          currentYear: yearDashboard,
        });
      }

      setCashFlow(cashFlowArr);
      setCashReceive(totals.totalReceive);
      setCashPay(totals.totalPay);
    }
  }

  async function getSells() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter((submenu) => submenu.name == "Vendas")[0].id
      )
    ) {
      var requests =
        companyDashboard != "0"
          ? await api.get(`/requests/config-dashboard/${companyDashboard}`)
          : await api.get("/requests/config-dashboard");
      var serviceOrders =
        companyDashboard != "0"
          ? await api.post(
              `/service-order/config/dashboard/${companyDashboard}`
            )
          : await api.post("/service-order/config/dashboard");
      var pdvSales =
        companyDashboard != "0"
          ? await api.post(`/pdvSales/config-dashboard/${companyDashboard}`)
          : await api.post(`/pdvSales/config-dashboard`);

      const today = new Date();
      var salesArr: any = [];
      var totalValueSells = 0;
      var salesLength = 0;

      requests.data.sort(function(element1: any, element2: any) {
        var date1: any = new Date(element1.requestDate);
        var date2: any = new Date(element2.requestDate);
        return date1 - date2;
      });

      serviceOrders.data.sort(function(element1: any, element2: any) {
        var date1: any = new Date(element1.date);
        var date2: any = new Date(element2.date);
        return date1 - date2;
      });

      pdvSales.data.sort(function(element1: any, element2: any) {
        var date1: any = new Date(element1.createdDate);
        var date2: any = new Date(element2.createdDate);
        return date1 - date2;
      });

      if (typeShowDashboard == "month") {
        var initialMonth = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            monthDashboard == 0 ? today.getMonth() : monthDashboard - 1
          ),
        }).dateStr;
        var finalMonth = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            monthDashboard == 0 ? today.getMonth() + 1 : monthDashboard,
            0
          ),
        }).dateStr;

        var currentDayLoop = initialMonth;
        do {
          var vendas = 0;

          for (var request of requests.data) {
            var dateRequest = request.requestDate.split("-").reverse();

            if (dateRequest.join("/") == currentDayLoop) {
              vendas += Number(request.totalValue);
              totalValueSells += Number(request.totalValue);

              if (request.totalValue) {
                salesLength++;
              }
            }
          }

          for (var serviceOrder of serviceOrders.data) {
            var dateServiceOrder = serviceOrder.date.split("-").reverse();

            if (dateServiceOrder.join("/") == currentDayLoop) {
              vendas += Number(serviceOrder.totalValue);
              totalValueSells += Number(serviceOrder.totalValue);

              if (serviceOrder.totalValue) {
                salesLength++;
              }
            }
          }

          for (var sale of pdvSales.data) {
            var dateSale = new Date(sale.createdDate);
            var monthSale =
              dateSale.getMonth() + 1 < 10
                ? "0" + (dateSale.getMonth() + 1)
                : dateSale.getMonth() + 1;
            var daySale =
              dateSale.getDate() < 10
                ? "0" + dateSale.getDate()
                : dateSale.getDate();

            var dateSaleConverted =
              daySale + "/" + monthSale + "/" + dateSale.getFullYear();

            if (dateSaleConverted == currentDayLoop) {
              let saleValueConverted = formatIntegerToMoney(
                sale.totalValue,
                false
              )
                .replaceAll(".", "")
                .replace(",", ".");

              vendas += Number(saleValueConverted);
              totalValueSells += Number(saleValueConverted);

              if (Number(saleValueConverted)) {
                salesLength++;
              }
            }
          }

          salesArr.push({
            name: currentDayLoop.split("/")[0],
            vendas: vendas.toFixed(2),
          });

          var day =
            Number(currentDayLoop.split("/")[0]) + 1 < 10
              ? "0" + (Number(currentDayLoop.split("/")[0]) + 1)
              : Number(currentDayLoop.split("/")[0]) + 1;
          currentDayLoop =
            day +
            "/" +
            currentDayLoop.split("/")[1] +
            "/" +
            currentDayLoop.split("/")[2];
        } while (
          new Date(
            Number(currentDayLoop.split("/")[2]),
            Number(currentDayLoop.split("/")[1]) - 1,
            Number(currentDayLoop.split("/")[0])
          ) <=
          new Date(
            Number(finalMonth.split("/")[2]),
            Number(finalMonth.split("/")[1]) - 1,
            Number(finalMonth.split("/")[0])
          )
        );
      } else {
        var initialMonth = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            0
          ),
        }).dateStr;
        var finalMonth = getDate({
          initialDate: new Date(
            yearDashboard == 0 ? today.getFullYear() : yearDashboard,
            12,
            0
          ),
        }).dateStr;

        var currentDayLoop = initialMonth;
        do {
          var vendas = 0;

          for (var request of requests.data) {
            var dateRequest = request.requestDate.split("-").reverse();

            if (
              getNameMonth(dateRequest[1], "initials") ==
              getNameMonth(currentDayLoop.split("/")[1], "initials")
            ) {
              vendas += Number(request.totalValue);
              totalValueSells += Number(request.totalValue);

              if (request.totalValue) {
                salesLength++;
              }
            }
          }

          for (var serviceOrder of serviceOrders.data) {
            var dateServiceOrder = serviceOrder.date.split("-").reverse();

            if (
              getNameMonth(dateServiceOrder[1], "initials") ==
              getNameMonth(currentDayLoop.split("/")[1], "initials")
            ) {
              vendas += Number(serviceOrder.totalValue);
              totalValueSells += Number(serviceOrder.totalValue);

              if (serviceOrder.totalValue) {
                salesLength++;
              }
            }
          }

          for (var sale of pdvSales.data) {
            var dateSale = new Date(sale.createdDate);
            var monthSale =
              dateSale.getMonth() + 1 < 10
                ? "0" + (dateSale.getMonth() + 1)
                : dateSale.getMonth() + 1;
            var daySale =
              dateSale.getDay() + 1 < 10
                ? "0" + (dateSale.getDay() + 1)
                : dateSale.getDay() + 1;

            if (
              getNameMonth(String(monthSale), "initials") ==
              getNameMonth(currentDayLoop.split("/")[1], "initials")
            ) {
              let saleValueConverted = formatIntegerToMoney(
                sale.totalValue,
                false
              )
                .replaceAll(".", "")
                .replace(",", ".");

              vendas += Number(saleValueConverted);
              totalValueSells += Number(saleValueConverted);

              if (Number(saleValueConverted)) {
                salesLength++;
              }
            }
          }

          salesArr.push({
            name: getNameMonth(currentDayLoop.split("/")[1], "initials"),
            vendas: vendas.toFixed(2),
          });

          var month =
            Number(currentDayLoop.split("/")[1]) + 1 < 10
              ? "0" + (Number(currentDayLoop.split("/")[1]) + 1)
              : Number(currentDayLoop.split("/")[1]) + 1;
          currentDayLoop =
            currentDayLoop.split("/")[0] +
            "/" +
            month +
            "/" +
            currentDayLoop.split("/")[2];
        } while (
          new Date(
            Number(currentDayLoop.split("/")[2]),
            Number(currentDayLoop.split("/")[1]) - 1,
            Number(currentDayLoop.split("/")[0])
          ) <=
          new Date(
            Number(finalMonth.split("/")[2]),
            Number(finalMonth.split("/")[1]) - 1,
            Number(finalMonth.split("/")[0])
          )
        );
      }

      setSells(salesArr);
      setTotalSells(totalValueSells);
      setTicketAverage(salesLength ? totalValueSells / salesLength : 0);
    }
  }

  async function getBillings() {
    const permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "Faturamento por Cliente"
          )[0].id
      )
    ) {
      var response = await api.post(
        `/config/dashboard/all${
          companyDashboard != "0" ? "/" + companyDashboard : ""
        }`,
        {
          yearDashboard,
          monthDashboard,
          typeShowDashboard,
          companyId: currentCompany?.id,
        }
      );

      setBillingPerCustomers(response.data.billingPerCustomers);
      setBillingPerProductsServices(response.data.billingPerProductsServices);
    }
  }

  async function getPdv() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter((submenu) => submenu.name == "Saldos PDVs")[0].id
      )
    ) {
      const resultSales =
        companyDashboard != "0"
          ? await api.post(`/pdvSales/config-dashboard/${companyDashboard}`)
          : await api.post(`/pdvSales/config-dashboard`);
      const cashRegisters =
        companyDashboard != "0"
          ? await api.post(
              `/pdvCashRegister/config/dashboard/${companyDashboard}`
            )
          : await api.post(`/pdvCashRegister/config/dashboard`);
      const users = await api.post(`/users/config/dashboard`);

      const sales: PdvSale[] = resultSales.data;

      var total = 0;
      var pdvArr = [];
      var cashRegister = cashRegisters.data.filter(
        (cash: any) => cash.active == "y"
      );
      for (const cash of cashRegister) {
        let limitValue = formatToFloat(formatIntegerToMoney(cash.limitValue));
        let totalCashier = 0;
        let totalChange = 0;
        total += formatToFloat(formatIntegerToMoney(cash.currentValue));

        for (const sale of sales) {
          if (sale.cashRegisterId == cash.id) {
            const resultPayments = await api.get(
              `/pdvSalePayments/pdvSale/${sale.id}`
            );
            const payments: PdvSalePayment[] = resultPayments.data;

            totalChange += formatToFloat(
              formatIntegerToMoney(sale.changeValue)
            );
            for (const payment of payments) {
              if (payment.method === "money" && payment.saleId == sale.id) {
                totalCashier += formatToFloat(
                  formatIntegerToMoney(payment.paidValue)
                );
              }
            }

            totalCashier -= totalChange;
          }
        }

        var operatorPdv = users.data.find(
          (user: any) => user.id == cash.userId
        );
        var company = await api.get(`/companies/${cash.companyId}`);
        pdvArr.push({
          ...cash,
          operator: operatorPdv
            ? operatorPdv.firstname + " " + operatorPdv.lastname
            : "",
          totalCashier: totalCashier,
          alert:
            cash.limitValue > 0 && totalCashier > limitValue ? true : false,
          companyName: company.data.name,
        });
      }

      setBalancePdvs(pdvArr);
      setTotalPdvs(total);
    }
  }

  async function getRankingExpenses() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "Ranking Despesas - TOP 15"
          )[0].id
      )
    ) {
      var subCategorys =
        companyDashboard != "0"
          ? await api.get(
              `/dre/config-dashboard/sub/expense/${companyDashboard}`
            )
          : await api.get("/dre/config-dashboard/sub/expense");
      var bills =
        companyDashboard != "0"
          ? await api.post(`/billsToPay/ranking-dashboard/${companyDashboard}`)
          : await api.post(`/billsToPay/ranking-dashboard`);
      var billsPartial = await api.post(
        "cashFlow/config-dashboard/billsToPay/partial",
        { company: companyDashboard != "0" ? companyDashboard : "" }
      );

      var subCategorysArr: any[] = [];
      subCategorys.data.map(async (subCategory: any) => {
        var total = 0;

        bills.data.map(async (bill: any) => {
          if (subCategory.id == bill.dreSubCategoryId) {
            total += bill.amount;
          }
        });

        billsPartial.data.map(async (bill: any) => {
          if (subCategory.id == bill.dreSubCategoryId) {
            var partials = JSON.parse(bill.partialPayments);

            for (var partial of partials) {
              total += partial.totalPaid;
            }
          }
        });

        if (total) {
          subCategorysArr.push({
            id: subCategory.id,
            name: subCategory.name,
            value: total,
          });
        }
      });

      subCategorysArr.sort(function(element1: any, element2: any) {
        return element2.value - element1.value;
      });

      setRankingExpenses(subCategorysArr);
    }
  }

  async function getRankingRevenues() {
    var permissions = JSON.parse(user.permissions);
    if (
      user &&
      menus &&
      permissions.includes(
        menus
          .filter((menu) => menu.name == "Dashboard")[0]
          ?.submenus?.filter(
            (submenu) => submenu.name == "Ranking Receitas - TOP 15"
          )[0].id
      )
    ) {
      var subCategorys =
        companyDashboard != "0"
          ? await api.get(
              `/dre/config-dashboard/sub/revenue/${companyDashboard}`
            )
          : await api.get("/dre/config-dashboard/sub/revenue");
      var bills =
        companyDashboard != "0"
          ? await api.post(
              `/billsToReceive/ranking-dashboard/${companyDashboard}`
            )
          : await api.post(`/billsToReceive/ranking-dashboard`);
      var billsPartial = await api.post(
        "cashFlow/config-dashboard/billsToReceive/partial",
        { company: companyDashboard != "0" ? companyDashboard : "" }
      );

      var subCategorysArr: any[] = [];
      subCategorys.data.map(async (subCategory: any) => {
        var total = 0;

        bills.data.map(async (bill: any) => {
          if (subCategory.id == bill.dreSubCategoryId) {
            total += bill.totalPaid ? bill.totalPaid : bill.amount;
          }
        });

        billsPartial.data.map(async (bill: any) => {
          if (subCategory.id == bill.dreSubCategoryId) {
            var partials = JSON.parse(bill.partialPayments);

            for (var partial of partials) {
              total += partial.totalPaid;
            }
          }
        });

        if (total) {
          subCategorysArr.push({
            id: subCategory.id,
            name: subCategory.name,
            value: total,
          });
        }
      });

      subCategorysArr.sort(function(element1: any, element2: any) {
        return element2.value - element1.value;
      });

      setRankingRevenues(subCategorysArr);
    }
  }

  const handleCashFlowCompositeChartTooltipTitle = useCallback(
    (element: any) => {
      if (typeShowDashboard === "month") {
        return CashFlowService.getCashFlowCompositeChartTooltipTitle({
          element,
          dateType: "month",
          currentYear: yearDashboard,
          currentMonth: monthDashboard,
        });
      } else {
        return CashFlowService.getCashFlowCompositeChartTooltipTitle({
          element,
          dateType: "year",
          currentYear: yearDashboard,
        });
      }
    },
    [typeShowDashboard, yearDashboard, monthDashboard]
  );

  const btnSuccess = {
    color: "#fff",
    background: "green",
    border: "none",
  };

  const btnDanger = {
    color: "#fff",
    background: "red",
    border: "none",
  };

  const styleOverflow = {
    height: "360px",
    maxHeight: "360px",
    overflow: "auto",
  };

  const stylePdvOverflow = {
    height: "325px",
    maxHeight: "325px",
    overflow: "auto",
  };

  return (
    <>
      {companyplan?.expirationDate &&
        companyplan.daysToExpire >= 0 &&
        companyplan.daysToExpire <= 3 && (
          <div className="card card-body pt-4">
            <div className="col-lg-12 mt-3">
              <div className="alert alert-danger">
                Faltam {companyplan.daysToExpire} dias para o seu plano expirar!
                Realize o pagamento para renovar
              </div>
            </div>
          </div>
        )}

      <div className="row">
        {fastAccess.length ? (
          <div className="d-flex flex-column col-12">
            <ButtonGroup className="flex-wrap">
              {modulesSystem
                .filter((moduleData: any) => fastAccess.includes(moduleData.id))
                .map((moduleMenu: any) => {
                  var modulesWithoudPageAdd = [
                    11,
                    16,
                    26,
                    27,
                    73,
                    35,
                    36,
                    37,
                    38,
                    39,
                    41,
                    42,
                    43,
                    45,
                    46,
                    47,
                    48,
                    49,
                    50,
                    51,
                    53,
                    54,
                    55,
                    56,
                    57,
                    58,
                    59,
                    60,
                    61,
                    62,
                    63,
                    64,
                    66,
                    67,
                    69,
                    70,
                    71,
                    74,
                  ];
                  var page = "";

                  if (
                    !modulesWithoudPageAdd.find((module: any) => {
                      return module == moduleMenu.id;
                    })
                  ) {
                    page = "/adicionar";
                  }

                  if (moduleMenu.route) {
                    return (
                      <Button
                        href={moduleMenu.route + page}
                        key={moduleMenu.id}
                        variant="warning"
                        className="mb-3"
                      >
                        Novo(a) {moduleMenu.name}
                      </Button>
                    );
                  }
                })}
            </ButtonGroup>
          </div>
        ) : (
          <></>
        )}

        <div className="col-12 mb-3">
          <div className="card card-custom gutter-b">
            <div className="card-body d-flex flex-row align-items-center justify-content-between pt-3 pb-3 pr-5 pl-5">
              <div className="d-flex flex-row align-items-center">
                <b className="mr-5">
                  <i className="flaticon2-calendar"></i>&nbsp; Data da Dashboard
                </b>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Mês</Grid>
                    <Grid item>
                      <AntSwitch
                        checked={typeShowDashboard == "year" ? true : false}
                        onChange={(e: any) => {
                          e.target.checked
                            ? setTypeShowDashboard("year")
                            : setTypeShowDashboard("month");
                          setMonthDashboard(new Date().getMonth() + 1);
                        }}
                      />
                    </Grid>
                    <Grid item>Ano</Grid>
                  </Grid>
                </Typography>
              </div>

              <div className="row col-lg-6 justify-content-end">
                {typeShowDashboard == "month" ? (
                  <div className="col-lg-3">
                    <TextField
                      size="small"
                      select
                      label="Mês"
                      margin="normal"
                      variant="outlined"
                      value={monthDashboard}
                      onChange={(e: any) =>
                        setMonthDashboard(Number(e.target.value))
                      }
                    >
                      <MenuItem key="0" value="1">
                        Janeiro
                      </MenuItem>

                      <MenuItem key="1" value="2">
                        Fevereiro
                      </MenuItem>

                      <MenuItem key="2" value="3">
                        Março
                      </MenuItem>

                      <MenuItem key="3" value="4">
                        Abril
                      </MenuItem>

                      <MenuItem key="4" value="5">
                        Maio
                      </MenuItem>

                      <MenuItem key="5" value="6">
                        Junho
                      </MenuItem>

                      <MenuItem key="6" value="7">
                        Julho
                      </MenuItem>

                      <MenuItem key="7" value="8">
                        Agosto
                      </MenuItem>

                      <MenuItem key="8" value="9">
                        Setembro
                      </MenuItem>

                      <MenuItem key="9" value="10">
                        Outubro
                      </MenuItem>

                      <MenuItem key="10" value="11">
                        Novembro
                      </MenuItem>

                      <MenuItem key="11" value="12">
                        Dezembro
                      </MenuItem>
                    </TextField>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-lg-3">
                  <TextField
                    size="small"
                    select
                    label="Ano"
                    margin="normal"
                    variant="outlined"
                    value={yearDashboard}
                    onChange={(e: any) =>
                      setYearDashboard(Number(e.target.value))
                    }
                  >
                    {yearsAvailable.map((year: any, index: number) => {
                      return (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>

                <div className="col-lg-6">
                  {companyDashboard.length > 0 ? (
                    <Autocomplete
                      options={mainAndBranches}
                      getOptionLabel={(option: any) => option.name}
                      value={
                        mainAndBranches.find(
                          (e) => String(e.id) === companyDashboard
                        ) ?? { id: 0, name: "" }
                      }
                      onChange={(_: any, value: any) =>
                        setCompanyDashboard(value ? String(value.id) : "")
                      }
                      renderInput={(props: any) => (
                        <TextField
                          {...props}
                          label="Empresa"
                          margin="normal"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      options={mainAndBranches}
                      getOptionLabel={(option: any) => option.name}
                      value={
                        mainAndBranches.find(
                          (e) => e.id === currentCompany?.id
                        ) ?? { id: 0, name: "" }
                      }
                      onChange={(_: any, value: any) =>
                        setCompanyDashboard(value ? String(value.id) : "")
                      }
                      renderInput={(props: any) => (
                        <TextField
                          {...props}
                          label="Empresa"
                          margin="normal"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) =>
                    submenu.name == "A Receber Hoje / Recebimentos em Atraso"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-row align-items-center justify-content-around pb-3">
                  <div className="col-lg-6 d-flex flex-column align-items-center justify-content-between flex-wrap">
                    <b>A Receber Hoje</b>
                    <h1 style={{ color: "green" }}>
                      {formatCurrency(todayReceive)}
                    </h1>
                  </div>
                  <div className="col-lg-6 d-flex flex-column align-items-center flex-wrap border-left">
                    <b>Recebimentos em Atraso</b>
                    <h1 style={{ color: "green", opacity: 0.4 }}>
                      {formatCurrency(todayReceiveLate)}
                    </h1>
                  </div>
                </div>
                <div className="card-body col-12 d-flex justify-content-center pt-0">
                  <a
                    href="/contas-a-receber/adicionar"
                    style={btnSuccess}
                    className="btn btn-sm btn-primary mr-3"
                  >
                    Novo Recebimento
                  </a>
                  <a
                    href="/contas-a-receber"
                    className="btn btn-sm btn-outline-light"
                  >
                    Ver todos
                  </a>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) =>
                    submenu.name == "A Pagar Hoje / Pagamentos em Atraso"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-row align-items-center justify-content-around pb-3">
                  <div className="col-lg-6 d-flex flex-column align-items-center justify-content-between flex-wrap">
                    <b>A Pagar Hoje</b>
                    <h1 style={{ color: "red" }}>{formatCurrency(todayPay)}</h1>
                  </div>
                  <div className="col-lg-6 d-flex flex-column align-items-center flex-wrap border-left">
                    <b>Pagamentos em Atraso</b>
                    <h1 style={{ color: "red", opacity: 0.4 }}>
                      {formatCurrency(todayPayLate)}
                    </h1>
                  </div>
                </div>
                <div className="card-body col-12 d-flex justify-content-center pt-0">
                  <a
                    href="/contas-a-pagar/adicionar"
                    style={btnDanger}
                    className="btn btn-sm btn-primary mr-3"
                  >
                    Novo Pagamento
                  </a>
                  <a
                    href="/contas-a-pagar"
                    className="btn btn-sm btn-outline-light"
                  >
                    Ver todos
                  </a>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Fluxo de Caixa"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap pb-2">
                  <div className="col-12 d-flex flex-column mb-3">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <b>Fluxo de Caixa</b>
                      <a
                        href="/fluxo-de-caixa"
                        className="btn btn-sm btn-outline-light"
                      >
                        Ver detalhes
                      </a>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Previsto</Grid>
                          <Grid item>
                            <AntSwitch
                              checked={
                                typeShow == "accomplished" ? true : false
                              }
                              onChange={(e: any) => {
                                e.target.checked
                                  ? setTypeShow("accomplished")
                                  : setTypeShow("foreseen");
                              }}
                            />
                          </Grid>
                          <Grid item>Realizado</Grid>
                        </Grid>
                      </Typography>
                    </div>
                  </div>

                  <CashFlowComposedChart
                    cashFlow={cashFlow}
                    cashReceive={cashReceive}
                    cashPay={cashPay}
                    tooltipTitleHandler={
                      handleCashFlowCompositeChartTooltipTitle
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          {user &&
          menus &&
          !JSON.parse(user.permissions).includes(
            menus
              .filter((menu) => menu.name == "Dashboard")[0]
              ?.submenus?.filter(
                (submenu) => submenu.name == "Saldo Total Por Conta"
              )[0].id
          ) ? (
            <></>
          ) : (
            <>
              <BankAccountsTotalsPieChart
                title="Saldo Total Por Conta"
                totalBalance={
                  user &&
                  menus &&
                  JSON.parse(user.permissions).includes(
                    menus
                      .filter((menu) => menu.name == "Dashboard")[0]
                      ?.submenus?.filter(
                        (submenu) => submenu.name == "Saldo Total Por Conta"
                      )[0].id
                  )
                    ? totalBalance
                    : 0
                }
                showDetailsLink
                activeData={activeData}
                setActiveData={setActiveData}
                totalAccounts={totalAccounts}
              />
            </>
          )}
        </div>

        <div className="col-lg-12 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) =>
                    submenu.name == "Calendário de Lançamentos e Eventos"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap pb-2">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
                    <b>Calendário de Lançamentos e Eventos</b>
                  </div>

                  <Calendar
                    company={companyDashboard}
                    currentCompanyId={currentCompany?.id}
                    showMonth={typeShowDashboard == "month" ? "actual" : "all"}
                    month={monthDashboard}
                    year={yearDashboard}
                    dateSelected={dateSelected}
                    setDateSelected={setDateSelected}
                    setMonth={setMonthDashboard}
                    setYear={setYearDashboard}
                    userHasAccess={
                      user &&
                      menus &&
                      !JSON.parse(user.permissions).includes(
                        menus
                          .filter((menu) => menu.name == "Dashboard")[0]
                          ?.submenus?.filter(
                            (submenu) =>
                              submenu.name ==
                              "Calendário de Lançamentos e Eventos"
                          )[0].id
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter((submenu) => submenu.name == "Vendas")[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap pb-2">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-3">
                    <b>Vendas</b>

                    <a
                      href="/fluxo-de-caixa"
                      className="btn btn-sm btn-outline-light"
                    >
                      Ver detalhes
                    </a>
                  </div>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart width={1000} height={300} data={sells}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip content={renderTooltipSells} />
                      <Legend />
                      <Bar dataKey="vendas" fill="green" />
                    </BarChart>
                  </ResponsiveContainer>

                  <div className="row col-12 d-flex flex-row justify-content-between align-items-center mt-3 pt-3 border-top">
                    <div className="col-sm-12 col-lg-6 d-flex flex-column align-items-center">
                      <b>Total de Vendas</b>
                      <h3 className="mt-3" style={{ color: "green" }}>
                        {formatCurrency(totalSells)}
                      </h3>
                    </div>
                    <div className="col-sm-12 col-lg-6 d-flex flex-column align-items-center">
                      <b>Ticket Médio</b>
                      <h3 className="mt-3">{formatCurrency(ticketAverage)}</h3>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Saldos PDVs"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
                    <b>Saldos PDVs</b>
                  </div>

                  {balancePdvs.length ? (
                    <div className="row col-12" style={{ height: "360px" }}>
                      <div className="col-12 d-flex justify-content-start pl-0">
                        <b>
                          Saldo Geral:{" "}
                          <label style={{ color: "green" }}>
                            {formatCurrency(totalPdvs)}
                          </label>
                        </b>
                      </div>

                      <div
                        className="col-12 d-flex flex-column mt-3"
                        style={stylePdvOverflow}
                      >
                        {balancePdvs.map((pdv) => {
                          return (
                            <div
                              key={pdv.id}
                              className="d-flex flex-column mb-3 pb-3 border-bottom pl-0"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <TooltipMaterialUi
                                  TransitionComponent={Zoom}
                                  title={
                                    pdv.alert
                                      ? "Alerta de Sangria"
                                      : `Caixa Aberto`
                                  }
                                >
                                  <i
                                    className={
                                      pdv.alert
                                        ? "flaticon-warning mr-3"
                                        : "flaticon2-information mr-3"
                                    }
                                    style={{
                                      color: pdv.alert ? "red" : "#009283",
                                      fontSize: "30px",
                                    }}
                                  ></i>
                                </TooltipMaterialUi>
                                <div className="d-flex flex-column ml-3">
                                  <p className="m-0">
                                    Operador: <b>{pdv.operator}</b>
                                  </p>
                                  <p className="m-0">
                                    Empresa: <b>{pdv.companyName}</b>
                                  </p>
                                  <p className="m-0">
                                    Pdv Aberto Em: <b>{pdv.openedDate}</b>
                                  </p>
                                  <p className="m-0">
                                    Total em Caixa:{" "}
                                    <b>
                                      {formatCurrency(
                                        formatToFloat(
                                          formatIntegerToMoney(pdv.currentValue)
                                        )
                                      )}
                                    </b>
                                  </p>
                                  <p className="m-0">
                                    Total em Dinheiro:{" "}
                                    <b>{formatCurrency(pdv.totalCashier)}</b>
                                  </p>
                                  <p className="m-0">
                                    Limite da Sangria:{" "}
                                    <b>
                                      {formatCurrency(
                                        formatToFloat(
                                          formatIntegerToMoney(pdv.limitValue)
                                        )
                                      )}
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="col-12" style={{ height: "360px" }}>
                      <p style={{ color: "#ccc" }}>
                        Nenhum pdv ultrapassou o limite de sangria até o
                        momento...
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Faturamento por Cliente"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
    <b>Faturamento por Cliente</b>
  </div>

  {billingPerCustomers.length ? (
    <div className="col-12 d-flex flex-column" style={styleOverflow}>
      {billingPerCustomers.slice(0, 15).map((customer, index) => {  // Limita a 15 clientes
        let total = 0;

        for (const i of billingPerCustomers) {
          total += i.value;
        }

        const percent = (Number(customer.value) / Number(total)) * 100;

        return (
          <div
            key={customer.id}
            className="d-flex flex-row align-items-center justify-content-between mb-3"
          >
            <div className="col-7">
              <b>{customer.name}</b>
            </div>

            <div className="col-5 d-flex justify-content-between">
              <b className="mr-3" style={{ color: "green" }}>
                {formatCurrency(customer.value)}
              </b>
              <b>{`${total > 0 ? percent.toFixed(2) : " - "}%`}</b>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="col-12 d-flex flex-column" style={styleOverflow}>
      <p style={{ color: "#ccc" }}>
        Nenhum faturamento por cliente até o momento...
      </p>
    </div>
  )}
</div>

              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Ranking de Produtos/Serviços"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
                    <b>Ranking de Produtos/Serviços</b>
                  </div>

                  {billingPerProductsServices.length ? (
            <div className="col-12 d-flex flex-column" style={styleOverflow}>
              {billingPerProductsServices.slice(0, 15).map((data, index) => {  // Limita a 15 produtos/serviços
                let total = 0;

                for (const i of billingPerProductsServices) {
                  total += i.value;
                }

                const percent = (Number(data.value) / Number(total)) * 100;

                        return (
                          <div
                            key={data.id}
                            className="w-100 d-flex flex-row align-items-center justify-content-between mb-3"
                          >
                            <div className="col-7">
                              <b>
                                #{index + 1} - {data.name}
                              </b>
                            </div>

                            <div className="col-5 d-flex justify-content-between">
                              <b className="mr-3" style={{ color: "green" }}>
                                {formatCurrency(data.value)}
                              </b>
                              <b>{`${
                                total > 0 ? percent.toFixed(2) : " - "
                              } %`}</b>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      className="col-12 d-flex flex-column"
                      style={styleOverflow}
                    >
                      <p style={{ color: "#ccc" }}>
                        Nenhum faturamento por produto ou serviço até o
                        momento...
                      </p>
                    </div>

                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Ranking Receitas - TOP 15"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
                    <b>Ranking Receitas - TOP 15</b>
                  </div>

                  {rankingRevenues.length ? (
                    <div
                      className="col-12 d-flex flex-column"
                      style={styleOverflow}
                    >
                      {rankingRevenues.map((expense: any, index: number) => {
                        if (index <= 14) {
                          let total = 0;

                          for (const i of rankingRevenues) {
                            total += i.value;
                          }

                          const percent = (expense.value / Number(total)) * 100;

                          return (
                            <div
                              key={expense.id}
                              className="d-flex flex-row align-items-center justify-content-between mb-3"
                            >
                              <div className="col-7">
                                <b>
                                  #{index + 1} - {expense.name}
                                </b>
                              </div>

                              <div className="col-5 d-flex justify-content-between">
                                <b className="mr-3" style={{ color: "green" }}>
                                  {formatCurrency(expense.value)}
                                </b>
                                <b>{`${
                                  total > 0 ? percent.toFixed(2) : "0"
                                } %`}</b>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <div
                      className="col-12 d-flex flex-column"
                      style={styleOverflow}
                    >
                      <p style={{ color: "#ccc" }}>
                        Nenhuma receita criada até o momento...
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-3 mb-3">
          <div className="card card-custom gutter-b">
            {user &&
            menus &&
            !JSON.parse(user.permissions).includes(
              menus
                .filter((menu) => menu.name == "Dashboard")[0]
                ?.submenus?.filter(
                  (submenu) => submenu.name == "Ranking Despesas - TOP 15"
                )[0].id
            ) ? (
              <></>
            ) : (
              <>
                <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
                  <div className="col-12 d-flex flex-row align-items-center justify-content-between mb-7">
                    <b>Ranking Despesas - TOP 15</b>
                  </div>

                  {rankingExpenses.length ? (
                    <div
                      className="col-12 d-flex flex-column"
                      style={styleOverflow}
                    >
                      {rankingExpenses.map((expense: any, index: number) => {
                        if (index <= 14) {
                          let total = 0;

                          for (const i of rankingExpenses) {
                            total += i.value;
                          }

                          const percent =
                            (Number(expense.value) / Number(total)) * 100;

                          return (
                            <div
                              key={expense.id}
                              className="d-flex flex-row align-items-center justify-content-between mb-3"
                            >
                              <div className="col-7">
                                <b>
                                  {" "}
                                  #{index + 1} - {expense.name}
                                </b>
                              </div>

                              <div className="col-5 d-flex justify-content-between">
                                <b className="mr-3" style={{ color: "green" }}>
                                  {formatCurrency(expense.value)}
                                </b>
                                <b>{`${
                                  total > 0 ? percent.toFixed(2) : " - "
                                } %`}</b>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <div
                      className="col-12 d-flex flex-column"
                      style={styleOverflow}
                    >
                      <p style={{ color: "#ccc" }}>
                        Nenhuma despesa criada até o momento...
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>{ToastElement}</div>
    </>
  );
}
