import React, { useState } from "react";
import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import { Button, Spinner, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import logService from "../../services/LogService";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type Inputs = {
  name: string;
  description: string;
  filePath: string;
  replacementFields: { [key: string]: string };
};

function NewContractTemplate() {
  const classes = useStyles();
  const subHeader = useSubheader();
  subHeader.setTitle("Novo Modelo de Contrato");

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    filePath: "",
    replacementFields: {},
  });
  const [replacementFields, setReplacementFields] = useState<
    { key: string; value: string }[]
  >([{ key: "", value: "" }]);

  const [modelFile, setModelFile] = useState<File | null>(null);

  const handleChangeModelFile = (fileList: FileList | null) => {
    setModelFile(fileList && fileList.length > 0 ? fileList[0] : null);
  };

  const handleAddField = () => {
    setReplacementFields([...replacementFields, { key: "", value: "" }]);
  };

  const handleFieldChange = (
    index: number,
    field: "key" | "value",
    value: string
  ) => {
    const newFields = [...replacementFields];
    newFields[index][field] = value;
    setReplacementFields(newFields);

    // Convert to JSON string and update formData
    const fieldsObject = newFields.reduce((acc: any, curr) => {
      if (curr.key && curr.value) {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});

    handleInputChange("replacementFields", JSON.stringify(fieldsObject));
  };
  const handleInputChange = (field: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await api.post("/contract-templates", formData, {});

      logService.logRegister({
        itemId: response.data.id,
        module: "Modelos de Contrato",
        itemName: response.data.name,
      });

      if (modelFile) {
        var formFile = new FormData();
        formFile.append("file", modelFile, modelFile?.name);
        const modelFileResponse = await api.post("/file", formFile);

        await api.put(
          `/contract-templates/${response.data.id}`,
          { ...formData, filePath: modelFileResponse.data },
          {}
        );
      }

      setShowModalSuccess(true);
    } catch (error) {
      setErrorMessage("Erro ao salvar o modelo de contrato");
      setShowModalError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className="row card card-body pt-2">
      <form onSubmit={handleSubmit}>
        <div className="col-lg-12 d-flex justify-content-end p-4 mb-3">
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <span>Salvar</span>
            )}
          </Button>
        </div>

        <Grid container direction="column" spacing={0}>
          <Grid item lg={6}>
            <TextField
              fullWidth
              size="small"
              label="Nome do Modelo"
              margin="normal"
              variant="outlined"
              value={formData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              fullWidth
              size="small"
              label="Descrição"
              margin="normal"
              variant="outlined"
              value={formData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item lg={6} style={{ marginBottom: 20 }}>
            <label htmlFor="formFile" className="mt-5">
              Modelo (.doc,.docx,.rtf) *
            </label>
            <input
              className="form-control"
              type="file"
              id="ModelFile"
              onChange={(e) => handleChangeModelFile(e.target.files)}
              accept=".doc,.docx,.rtf"
              required
            />
          </Grid>

          <Grid item lg={12}>
            <div className="replacement-fields-container">
              {replacementFields.map((field, index) => (
                <div key={index} className="d-flex mb-2">
                  <TextField
                    size="small"
                    label="Chave"
                    variant="outlined"
                    value={field.key}
                    onChange={(e) =>
                      handleFieldChange(index, "key", e.target.value)
                    }
                    className="mr-2"
                  />
                  <TextField
                    size="small"
                    label="Valor"
                    variant="outlined"
                    value={field.value}
                    onChange={(e) =>
                      handleFieldChange(index, "value", e.target.value)
                    }
                  />
                  {index === replacementFields.length - 1 && (
                    <Button
                      variant="outline-primary"
                      className="ml-2"
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </form>

      <Modal
        show={showModalSuccess}
        onHide={() => {
          setShowModalSuccess(false);
          window.location.href = "/modelo-contrato";
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="flaticon2-check-mark icon-xl text-success mr-3"></i>
            Sucesso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Modelo de contrato salvo com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = "/modelo-contrato")}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalError}
        onHide={() => setShowModalError(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalError(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewContractTemplate;
