import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button, Nav, Spinner, Tab, Tabs } from 'react-bootstrap';
import ModalError from '../../../components/ModalError';
import api from '../../../services/Api';
import { api as ApiUrl } from '../../../services/ApiURL';
import { useHistory } from 'react-router';
import { formatToFloat } from '../../../utils/formatCurrency';
import { sanitizeInput, SanitizeInputType } from '../../../utils/sanitizeInput';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { PartnersObj } from '../../../types/Partners';
import { SendDocument } from './SendDocument';
import { ConfigNfse } from './ConfigNfse';
import { ConfigNfe } from './ConfigNfe';
import { ConfigNfce } from './ConfigNfce';
import { ConfigFiscalIssuance } from './ConfigFiscalIssuance';
import { useCompanyBranch } from '../../../hooks/companyBranch';
import CompanyService from '../../../services/CompanyService';
import { ListLogs } from './ListLogs';
import { ManageStatus } from './ManageStatus';
import { ConfigPdv } from './ConfigPdv';
import { useStyles } from '../../../hooks/styles';
import { cnpjSearch } from '../../../utils/cnpjSearch';
import { addressSearch } from '../../../utils/addressSearch';
import useAllUf from '../../../hooks/uf';
import { CompanyFormAccountant } from '../../../components/CompanyForm/CompanyFormAccountant';
import { CompanyAccountant } from '../../../types/CompanyAccountant';
import UserService from '../../../services/UserService';
import { Link } from 'react-router-dom';
import { NumericFormat } from '../../../components/NumericFormat';
import { PatternFormat } from '../../../components/PatternFormat';
import { ConfigFinancial } from './ConfigFinancial';
import { ConfigOptics } from './ConfigOptics';

type InfoAddressData = {
  zipcode?: string;
  type?: string;
};

export function EditCompanyData() {
  const classes = useStyles();

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();
  const { selectedCompany: company, reloadCompanyData } = useCompanyBranch();

  const [id, setId] = useState(0);
  const [activeTab, setActiveTab] = useState('details');
  const [activeSideTab, setActiveSideTab] = useState('details');

  const [companyMainUser, setCompanyMainUser] = useState<any>(null);

  /* Logo */
  const imgFileInput = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState('');
  const [savedLogo, setSavedLogo] = useState('');

  const [typePeople, setTypePeople] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [municipalRegistration, setMunicipalRegistration] = useState('');
  const [stateRegistration, setStateRegistration] = useState('');
  const [taxRegime, setTaxRegime] = useState('');
  const [cnae, setCnae] = useState('');
  const [rntrc, setRntrc] = useState('');
  const [responsible, setResponsible] = useState('');
  const [cell, setCell] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [notes, setNotes] = useState('');

  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const [nameInvalid, setNameInvalid] = useState(false);
  const [cpfInvalid, setCpfInvalid] = useState(false);
  const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
  const [cnpjInvalid, setCnpjInvalid] = useState(false);
  const [beforeDaysInvalid, setBeforeDaysInvalid] = useState(false);
  const [afterDaysInvalid, setAfterDaysInvalid] = useState(false);
  const [responsibleInvalid, setResponsibleInvalid] = useState(false);
  const [cellInvalid, setCellInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [zipCodeInvalid, setZipCodeInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [districtInvalid, setDistrictInvalid] = useState(false);
  const [cityInvalid, setCityInvalid] = useState(false);
  const [stateInvalid, setStateInvalid] = useState(false);

  /* Sócios */
  const [partners, setPartners] = useState<PartnersObj[]>([]);
  const [partnersIdsToDelete, setPartnersIdsToDelete] = useState<string[]>([]);

  /* Horário de funcionamento */
  const [onBusinessDay, setOnBusinessDay] = useState(false);
  const [onSaturday, setOnSaturday] = useState(false);
  const [onSunday, setOnSunday] = useState(false);
  const [businessDayOpenHour, setBusinessDayOpenHour] = useState('');
  const [saturdayOpenHour, setSaturdayOpenHour] = useState('');
  const [sundayOpenHour, setSundayOpenHour] = useState('');
  const [businessDayCloseHour, setBusinessDayCloseHour] = useState('');
  const [saturdayCloseHour, setSaturdayCloseHour] = useState('');
  const [sundayCloseHour, setSundayCloseHour] = useState('');

  /* Contabilidade */
  const [accountants, setAccountants] = useState<CompanyAccountant[]>([]);
  const [accountantsIdsToDelete, setAccountantsIdsToDelete] = useState<
    string[]
  >([]);

  // Parâmetros
  //   Envio
  const [sendBeforeDueDate, setSendBeforeDueDate] = useState('n');
  const [sendInDueDate, setSendInDueDate] = useState('n');
  const [sendAfterDueDate, setSendAfterDueDate] = useState('n');
  const [beforeDay, setBeforeDay] = useState('');
  const [afterDay, setAfterDay] = useState('');
  const [sendEmailBeforeDueDate, setSendEmailBeforeDueDate] = useState('');
  const [sendWhatsappBeforeDueDate, setSendWhatsappBeforeDueDate] = useState(
    '',
  );
  const [sendEmailInDueDate, setSendEmailInDueDate] = useState('');
  const [sendWhatsappInDueDate, setSendWhatsappInDueDate] = useState('');
  const [sendEmailAfterDueDate, setSendEmailAfterDueDate] = useState('');
  const [sendWhatsappAfterDueDate, setSendWhatsappAfterDueDate] = useState('');

  //   Notas Fiscais
  const [nfApiConfig, setNfApiConfig] = useState<any>();
  const [envMode, setEnvMode] = useState('production');

  const nfseConfigData = useRef<any>({});
  const nfeConfigData = useRef<any>({});
  const nfceConfigData = useRef<any>({});
  const [savedCertificate, setSavedCertificate] = useState<string | null>(null);

  // PDV
  const [pdvDreSubCategoryId, setPdvDreSubCategoryId] = useState(0);
  const [pdvStockLocationId, setPdvStockLocationId] = useState(0);
  const [templateOpticsMessage, setTemplateOpticsMessage] = useState('');

  // Financeiro
  const [
    blockDayForChangeOfBillsSituationOfPastMonth,
    setBlockDayForChangeOfBillsSituationOfPastMonth,
  ] = useState('');

  const [isSubmit, setIsSubmit] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [showModalError, setShowModalError] = useState(false);

  const { allStates } = useAllUf();

  useEffect(() => {
    if (!company) return;

    async function loadData() {
      setId(Number(company.id));
      setTypePeople(company.typePeople ?? '');
      setName(company.name ?? '');
      setCpf(company.cpf ?? '');
      setCorporateName(company.corporateName ?? '');
      setCnpj(company.cnpj ?? '');
      setMunicipalRegistration(company.municipalRegistration ?? '');
      setStateRegistration(company.stateRegistration ?? '');
      setTaxRegime(company.taxRegime ?? '');
      setCnae(company.cnae ?? '');
      setRntrc(company.rntrc ?? '');
      setResponsible(company.responsible ?? '');
      setCell(company.cell ?? '');
      setPhone(company.phone ?? '');
      setEmail(company.email ?? '');
      setWebsite(company.website ?? '');
      setNotes(company.notes ?? '');

      setZipCode(company.zipCode ?? '');
      setAddress(company.address ?? '');
      setDistrict(company.district ?? '');
      setNumber(company.number ?? '');
      setComplement(company.complement ?? '');
      setCity(company.city ?? '');
      setState(company.state ?? '');

      setOnBusinessDay(company.onBusinessDay === 'y' ? true : false);
      setOnSaturday(company.onSaturday === 'y' ? true : false);
      setOnSunday(company.onSunday === 'y' ? true : false);
      setBusinessDayOpenHour(company.businessDayOpenHour ?? '');
      setSaturdayOpenHour(company.saturdayOpenHour ?? '');
      setSundayOpenHour(company.sundayOpenHour ?? '');
      setBusinessDayCloseHour(company.businessDayCloseHour ?? '');
      setSaturdayCloseHour(company.saturdayCloseHour ?? '');
      setSundayCloseHour(company.sundayCloseHour ?? '');

      setSendBeforeDueDate(company.sendBeforeDueDate ?? 'n');
      setSendInDueDate(company.sendInDueDate ?? 'n');
      setSendInDueDate(company.templateOpticsMessage ?? '');
      setSendAfterDueDate(company.sendAfterDueDate ?? 'n');
      setBeforeDay(company.beforeDay ?? '');
      setAfterDay(company.afterDay ?? '');
      setSendEmailBeforeDueDate(company.sendEmailBeforeDueDate ?? 'n');
      setSendWhatsappBeforeDueDate(company.sendWhatsappBeforeDueDate ?? 'n');
      setSendEmailInDueDate(company.sendEmailInDueDate ?? 'n');
      setSendWhatsappInDueDate(company.sendWhatsappInDueDate ?? 'n');
      setSendEmailAfterDueDate(company.sendEmailAfterDueDate ?? 'n');
      setSendWhatsappAfterDueDate(company.sendWhatsappAfterDueDate ?? 'n');
      setTemplateOpticsMessage(company.templateOpticsMessage ?? '');

      setSavedCertificate(company.digitalCertificate);

      if (company.logo) {
        setLogo(`${ApiUrl}/${company.logo}`);
        setSavedLogo(company.logo);
      }

      setEnvMode(company.nfEnv ?? 'production');

      if (!company.pdvDreSubCategoryId) {
        const responseSaleDreSubcategory = await api.get('dre/sub/name', {
          params: { name: 'Vendas' },
        });
        setPdvDreSubCategoryId(responseSaleDreSubcategory.data.id);
      } else {
        setPdvDreSubCategoryId(company.pdvDreSubCategoryId);
      }

      setPdvStockLocationId(company.pdvStockLocationId ?? 0);
      setBlockDayForChangeOfBillsSituationOfPastMonth(
        String(company.blockDayForChangeOfBillsSituationOfPastMonth ?? ''),
      );

      await getAccountants(Number(company.id));

      await getPartners(Number(company.id));

      const mainUser = await UserService.getFirstUserByCompanyId(
        Number(company.mainCompanyId || company.id),
      );
      setCompanyMainUser(mainUser);
    }
    loadData();
  }, [company]);

  useEffect(() => {
    if (!company) return;
    (async () => {
      // Pega nfApiConfig atualizada
      const updatedCompany = await CompanyService.getCompanyById(company.id, {
        withNfApiConfig: true,
      });
      setNfApiConfig(updatedCompany.nfApiConfig);
    })();
  }, [savedCertificate, company]);

  async function getAccountants(id: number) {
    if (!id) {
      return;
    }
    const { data } = await api.get<CompanyAccountant[]>(
      `companies/${id}/accountants`,
    );
    if (data.length === 0) {
      return;
    }

    const savedAccountants = [];

    for (const accountant of data) {
      savedAccountants.push({
        id: String(accountant.id || ''),
        userId: String(accountant.userId || ''),
        name: accountant.name ?? '',
        cpf: accountant.cpf ?? '',
        cnpj: accountant.cnpj ?? '',
        crc: accountant.crc ?? '',
        email: accountant.email ?? '',
        cell: accountant.cell ?? '',
        phone: accountant.phone ?? '',
        zipCode: accountant.zipCode ?? '',
        address: accountant.address ?? '',
        district: accountant.district ?? '',
        city: accountant.city ?? '',
        state: accountant.state ?? '',
        number: accountant.number ?? '',
        complement: accountant.complement ?? '',
      });
    }

    setAccountants(savedAccountants);
  }

  async function getPartners(id: number) {
    if (!id) {
      return;
    }
    const { data } = await api.get(`companies/${id}/partners`);
    if (data.length === 0) {
      return;
    }

    const partnersFound: PartnersObj[] = data.map((obj: any) => {
      return {
        id: String(obj.id),
        name: String(obj.name ?? ''),
        cpf: String(obj.cpf ?? ''),
        phone: String(obj.phone ?? ''),
        cell: String(obj.cell ?? ''),
        rg: String(obj.rg ?? ''),
        email: String(obj.email ?? ''),
        birthDate: String(obj.birthDate ?? ''),
        share: String(obj.share ?? ''),
      };
    });

    setPartners(partnersFound);
  }

  function cleanInputs() {
    setName('');
    setCpf('');
    setCorporateName('');
    setCnpj('');
    setMunicipalRegistration('');
    setStateRegistration('');
    setTaxRegime('');
    setCnae('');
    setRntrc('');
  }

  async function CheckCnpj(value: string) {
    if (value) {
      const searchResponse = await cnpjSearch(value ?? '');

      if (searchResponse.status === 'ERROR') {
        setMsgError(searchResponse.message);
        setShowModalError(true);
        setCnpjInvalid(true);
        return;
      }

      setCorporateName(searchResponse.nome ?? '');
      setName(searchResponse.fantasia ?? '');
      setEmail(searchResponse.email ?? '');
      setPhone(searchResponse.telefone ?? '');

      setZipCode(searchResponse.cep || '');
      setAddress(searchResponse.logradouro || '');
      setDistrict(searchResponse.bairro || '');
      setCity(searchResponse.municipio || '');
      setState(searchResponse.uf || '');
      setNumber(searchResponse.numero || '');
      setComplement(searchResponse.complemento || '');
    }
  }

  async function CheckCnpjAccountant(value: string, index: number) {
    if (value) {
      const accountantListAux = accountants;
      const searchResponse = await cnpjSearch(value ?? '');

      if (searchResponse.status === 'ERROR') {
        setMsgError(searchResponse.message);
        setShowModalError(true);
        return;
      }

      accountantListAux[index].name = searchResponse.nome ?? '';
      accountantListAux[index].email = searchResponse.email ?? '';
      accountantListAux[index].phone = searchResponse.telefone ?? '';
      accountantListAux[index].zipCode = searchResponse.cep ?? '';
      accountantListAux[index].address = searchResponse.logradouro ?? '';
      accountantListAux[index].number = searchResponse.numero ?? '';
      accountantListAux[index].district = searchResponse.bairro ?? '';
      accountantListAux[index].complement = searchResponse.complemento ?? '';
      accountantListAux[index].city = searchResponse.municipio ?? '';
      accountantListAux[index].state = searchResponse.uf ?? '';

      setAccountants([...accountantListAux]);
    }
  }

  async function infoAddress({ zipcode }: InfoAddressData = {}) {
    const zipcodeState = zipCode;
    const targetZipcode = zipcode ? zipcode : zipcodeState;

    const address = await addressSearch(targetZipcode ?? '');

    setAddress(address.logradouro);
    setDistrict(address.bairro);
    setCity(address.localidade);
    setState(address.uf);
  }

  async function infoAddressAccountant(zipcode: string, index: number) {
    const address = await addressSearch(zipcode ?? '');

    const accountantListAux = accountants;

    accountantListAux[index].address = address.logradouro ?? '';
    accountantListAux[index].district = address.bairro ?? '';
    accountantListAux[index].city = address.localidade ?? '';
    accountantListAux[index].state = address.uf ?? '';

    setAccountants([...accountantListAux]);
  }

  function handleUploadClick() {
    if (imgFileInput && imgFileInput.current) {
      imgFileInput.current.click();
    }
  }

  function handleImgFileChange(event: any) {
    const input: HTMLInputElement = event.target;
    if (!input.files || input.files.length === 0) return;
    const fileUrl = window.URL.createObjectURL(input.files[0]);
    setLogo(fileUrl);
  }

  function handleSetPartnerField(index: number, field: string, value: string) {
    const aux = partners;
    if (field in aux[index] && field !== 'id') {
      const partnerObj = aux[index];
      type PartnerKey = keyof typeof partnerObj;
      aux[index][field as PartnerKey] = value;
    }

    setPartners([...aux]);
  }

  function handleAddPartnerClick() {
    const aux = partners;
    aux.push({
      id: '0',
      name: '',
      cpf: '',
      phone: '',
      cell: '',
      rg: '',
      email: '',
      birthDate: '',
      share: '',
    });

    setPartners([...aux]);
  }

  function handlePartnerRemove(index: number, id: string) {
    const aux = partners;
    const partner = aux[index];
    if (partner.id !== '0') {
      const ids = partnersIdsToDelete;
      const idExists = ids.find(idObj => idObj === partner.id);
      if (!idExists) {
        ids.push(partner.id);
        setPartnersIdsToDelete(ids);
      }
    }
    const partnersFiltered = aux.filter((obj, objIndex) => objIndex !== index);

    setPartners(partnersFiltered);
  }

  function handleSetAccountantField(index: number, field: string, value: any) {
    const aux = accountants;
    if (field in aux[index] && field !== 'id') {
      const accountantObj = aux[index];
      type AccountantKey = keyof typeof accountantObj;
      aux[index][field as AccountantKey] = value as never;
    }

    setAccountants([...aux]);
  }

  function handleAddAccountantClick() {
    const aux = accountants;
    aux.push({
      id: '',
      userId: '',
      name: '',
      cpf: '',
      cnpj: '',
      crc: '',
      email: '',
      cell: '',
      phone: '',
      zipCode: '',
      address: '',
      district: '',
      city: '',
      state: '',
      number: '',
      complement: '',
    });

    setAccountants([...aux]);
  }

  function handleAccountantRemove(index: number) {
    const aux = accountants;
    const accountant = aux[index];
    if (accountant.id) {
      const ids = accountantsIdsToDelete;
      const idExists = ids.find(idObj => idObj === String(accountant.id));
      if (!idExists) {
        ids.push(String(accountant.id));
        setAccountantsIdsToDelete(ids);
      }
    }
    const accountantsFiltered = aux.filter(
      (obj, objIndex) => objIndex !== index,
    );

    setAccountants(accountantsFiltered);
  }

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    setIsSubmit(true);

    const isValid = await inputsVerify();

    if (!isValid) {
      setIsSubmit(false);
      setShowModalError(true);
      return;
    }

    if (!partnersVerify()) return;

    if (!(await accountantsVerify())) {
      setIsSubmit(false);
      setShowModalError(true);
      return;
    }

    const raw = {
      typePeople: typePeople,
      name: name ? name : null,
      corporateName: corporateName ? corporateName : null,
      cpf: cpf ? sanitizeInput(SanitizeInputType.NUMERIC, cpf) : null,
      cnpj: cnpj ? sanitizeInput(SanitizeInputType.NUMERIC, cnpj) : null,
      stateRegistration: stateRegistration ? stateRegistration : null,
      municipalRegistration: municipalRegistration
        ? municipalRegistration
        : null,
      taxRegime: taxRegime ? taxRegime : null,
      cnae: cnae ? cnae : null,
      rntrc: rntrc ? rntrc : null,
      responsible: responsible ? responsible : null,
      cell: cell ? cell : null,
      phone: phone ? phone : null,
      email: email ? email : null,
      website: website ? website : null,
      zipCode: zipCode ? zipCode : null,
      address: address ? address : null,
      number: number ? number : null,
      district: district ? district : null,
      complement: complement ? complement : null,
      city: city ? city : null,
      state: state ? state : null,
      onBusinessDay: onBusinessDay ? 'y' : 'n',
      onSaturday: onSaturday ? 'y' : 'n',
      onSunday: onSunday ? 'y' : 'n',
      businessDayOpenHour:
        onBusinessDay && businessDayOpenHour ? businessDayOpenHour : null,
      saturdayOpenHour:
        onSaturday && saturdayOpenHour ? saturdayOpenHour : null,
      sundayOpenHour: onSunday && sundayOpenHour ? sundayOpenHour : null,
      businessDayCloseHour:
        onBusinessDay && businessDayCloseHour ? businessDayCloseHour : null,
      saturdayCloseHour:
        onSaturday && saturdayCloseHour ? saturdayCloseHour : null,
      sundayCloseHour: onSunday && sundayCloseHour ? sundayCloseHour : null,

      sendBeforeDueDate: sendBeforeDueDate,
      sendInDueDate: sendInDueDate,
      templateOpticsMessage: templateOpticsMessage,
      sendAfterDueDate: sendAfterDueDate,
      beforeDay: Number(beforeDay) ?? null,
      afterDay: Number(afterDay) ?? null,
      sendEmailBeforeDueDate: sendEmailBeforeDueDate,
      sendWhatsappBeforeDueDate: sendWhatsappBeforeDueDate,
      sendEmailInDueDate: sendEmailInDueDate,
      sendWhatsappInDueDate: sendWhatsappInDueDate,
      sendEmailAfterDueDate: sendEmailAfterDueDate,
      sendWhatsappAfterDueDate: sendWhatsappAfterDueDate,

      nfEnv: envMode,
      nfApiConfig: {
        nfse: nfseConfigData.current,
        nfe: nfeConfigData.current,
        nfce: nfceConfigData.current,
      },

      pdvDreSubCategoryId,
      pdvStockLocationId: pdvStockLocationId || null,
      blockDayForChangeOfBillsSituationOfPastMonth:
        blockDayForChangeOfBillsSituationOfPastMonth || null,
    };

    try {
      let companyResponse;
      try {
        companyResponse = await api.put(`companies/${id}`, raw);
        // console.log(companyResponse);
      } catch (error) {
        if (error)
          setMsgError(
            error.response?.data?.message ?? 'Erro ao enviar o certificado',
          );
        setIsSubmit(false);
        setShowModalError(true);
        return;
      }

      if (companyResponse.data?.nfApiConfig) {
        setNfApiConfig(companyResponse.data.nfApiConfig);
      }

      const cronCompany = await api.get(`/cronSendDocuments/${id}`);
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (cronCompany.data.length == 0) {
        await api.post(`/cronSendDocuments`, { companyId: id });
      } else {
        if (
          sendBeforeDueDate == 'n' &&
          sendInDueDate == 'n' &&
          sendAfterDueDate == 'n'
        ) {
          api.delete(`/cronSendDocuments/${id}`);
        } else {
          await api.post(`/cronSendDocuments/${cronCompany.data.id}`, {
            last_sended_at: today,
          });
        }
      }

      await deleteAccountants();

      if (accountants.length > 0) {
        for (const accountant of accountants) {
          await CompanyService.saveCompanyAccountantAndUpdateUser(
            accountant,
            companyResponse.data,
          );
        }
      }

      await deletePartners();

      if (partners.length > 0) {
        const aux = partners;
        const auxLength = aux.length;

        for (let i = 0; i < auxLength; i++) {
          const partner = aux[i];
          const partnerRaw = {
            companyId: id,
            name: partner.name ? partner.name : null,
            cpf: partner.cpf
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.cpf)
              : null,
            cell: partner.cell
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.cell)
              : null,
            phone: partner.phone
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.phone)
              : null,
            email: partner.email ? partner.email : null,
            birthDate: partner.birthDate ? partner.birthDate : null,
            rg: partner.rg ? partner.rg : null,
            share: partner.share ? formatToFloat(partner.share) : 0,
          };

          if (Number(partner.id)) {
            await api.put(`companyPartners/${partner.id}`, partnerRaw);
          } else {
            const { data } = await api.post(`companyPartners`, partnerRaw);
            partner.id = String(data.id);
            aux[i] = partner;
          }
        }
        setPartners(aux);
      }

      // Salvar config NFSE
      if (
        nfseConfigData.current &&
        Object.keys(nfseConfigData.current).length > 0 &&
        !(
          nfseConfigData.current.id &&
          Object.keys(nfseConfigData.current).length === 1
        )
      ) {
        const rawNfse = {
          nature: nfseConfigData.current.nature,
          taxRegime: nfseConfigData.current.taxRegime,
          specialTaxRegime: nfseConfigData.current.specialTaxRegime,
          aliquot: nfseConfigData.current.aliquot
            ? formatToFloat(nfseConfigData.current.aliquot)
            : undefined,
          holdIss: nfseConfigData.current.holdIss
            ? nfseConfigData.current.holdIss
              ? 'y'
              : 'n'
            : undefined,
          cofins: nfseConfigData.current.cofins
            ? formatToFloat(nfseConfigData.current.cofins)
            : undefined,
          pis: nfseConfigData.current.pis
            ? formatToFloat(nfseConfigData.current.pis)
            : undefined,
          csll: nfseConfigData.current.csll
            ? formatToFloat(nfseConfigData.current.csll)
            : undefined,
          ir: nfseConfigData.current.ir
            ? formatToFloat(nfseConfigData.current.ir)
            : undefined,
          inss: nfseConfigData.current.inss
            ? formatToFloat(nfseConfigData.current.inss)
            : undefined,
          holdCofins: nfseConfigData.current.holdCofins,
          holdPis: nfseConfigData.current.holdPis,
          holdCsll: nfseConfigData.current.holdCsll,
          holdIr: nfseConfigData.current.holdIr,
          holdInss: nfseConfigData.current.holdInss,
        };

        if (nfseConfigData.current.id) {
          await api.put(`nfseConfig/${nfseConfigData.current.id}`, rawNfse);
        } else {
          const nfseConfigResponse = await api.post('nfseConfig/', rawNfse);
          nfseConfigData.current.id = nfseConfigResponse.data.id;
        }
      }

      // Salvar config NFE
      if (
        nfeConfigData.current &&
        Object.keys(nfeConfigData.current).length > 0 &&
        !(
          nfeConfigData.current.id &&
          Object.keys(nfeConfigData.current).length === 1
        )
      ) {
        const rawNfe = {
          icmsCST: nfeConfigData.current.icmsCST,
          icmsModality: nfeConfigData.current.icmsModality,
          icmsStModality: nfeConfigData.current.icmsStModality,
          icmsStMva: nfeConfigData.current.icmsStMva
            ? formatToFloat(nfeConfigData.current.icmsStMva)
            : undefined,
          icmsStRate: nfeConfigData.current.icmsStRate
            ? formatToFloat(nfeConfigData.current.icmsStRate)
            : undefined,
          icmsStBcReduction: nfeConfigData.current.icmsStBcReduction
            ? formatToFloat(nfeConfigData.current.icmsStBcReduction)
            : undefined,
          ipiCST: nfeConfigData.current.ipiCST,
          ipiCode: nfeConfigData.current.ipiCode,
          pisCST: nfeConfigData.current.pisCST,
          cofinsCST: nfeConfigData.current.cofinsCST,
          typeAttendance: nfeConfigData.current.typeAttendance,
          cfopInUf: nfeConfigData.current.cfopInUf,
          cfopOutUf: nfeConfigData.current.cfopOutUf,
          nature: nfeConfigData.current.nature,
        };

        if (nfeConfigData.current.id) {
          await api.put(`nfeConfig/${nfeConfigData.current.id}`, rawNfe);
        } else {
          const nfeConfigResponse = await api.post('nfeConfig/', rawNfe);
          nfeConfigData.current.id = nfeConfigResponse.data.id;
        }
      }

      // Salvar config NFCE
      if (
        nfceConfigData.current &&
        Object.keys(nfceConfigData.current).length > 0 &&
        !(
          nfceConfigData.current.id &&
          Object.keys(nfceConfigData.current).length === 1
        )
      ) {
        const rawNfce = {
          icmsCST: nfceConfigData.current.icmsCST,
          icmsModality: nfceConfigData.current.icmsModality,
          pisCST: nfceConfigData.current.pisCST,
          cofinsCST: nfceConfigData.current.cofinsCST,
          typeAttendance: nfceConfigData.current.typeAttendance,
          cfopConsumer: nfceConfigData.current.cfopConsumer,
        };

        if (nfceConfigData.current.id) {
          await api.put(`nfceConfig/${nfceConfigData.current.id}`, rawNfce);
        } else {
          const nfceConfigResponse = await api.post('nfceConfig/', rawNfce);
          nfceConfigData.current.id = nfceConfigResponse.data.id;
        }
      }

      // Salvar Logo
      let uploadSuccess = true;
      try {
        uploadSuccess = await uploadLogo(id);
      } catch (error) {
        uploadSuccess = false;
        setMsgError('Erro ao salvar a imagem');
        setShowModalError(true);
      }

      if (uploadSuccess && savedLogo) {
        await removeOldLogo(savedLogo);
      }

      reloadCompanyData();

      setMsgError('Dados salvos com sucesso');
      setShowModalError(true);
      pushHistory(`${pathname}`);
    } catch (error) {
      console.log('error', error);
    }

    setIsSubmit(false);
  };

  async function uploadLogo(id: number): Promise<boolean> {
    if (imgFileInput && imgFileInput.current) {
      if (
        !imgFileInput.current.files ||
        imgFileInput.current.files.length === 0
      ) {
        return false;
      }
      const imageFile = imgFileInput.current.files[0];
      if (!imageFile) {
        return false;
      }

      var formFile = new FormData();
      formFile.append('image', imageFile, imageFile.name);

      const response = await api.post('/image', formFile);
      const raw = {
        logo: response.data,
      };

      await api.put(`companies/${id}`, raw);

      return response.data;
    }

    return false;
  }

  async function removeOldLogo(filename: string) {
    await api.delete('/file', { data: { filename } });
  }

  async function inputsVerify(): Promise<boolean> {
    if (!name) {
      setMsgError('Preencha o nome corretamente');
      setNameInvalid(true);
      return false;
    }
    if (!responsible) {
      setMsgError('Preencha o nome corretamente');
      setResponsibleInvalid(true);
      return false;
    }
    if (!cell) {
      setActiveTab('details');
      setMsgError('Preencha o celular corretamente');
      setCellInvalid(true);
      return false;
    }
    if (!email) {
      setActiveTab('details');
      setMsgError('Preencha o e-mail corretamente');
      setEmailInvalid(true);
      return false;
    }

    if (!phone || phone.replace(/\D/g, '').length < 7) {
      setActiveTab('details');
      setMsgError('Preencha o telefone corretamente');
      setPhoneInvalid(true);
      return false;
    }

    if (!(await verifyCnpjExists())) {
      setActiveTab('details');
      setMsgError('CNPJ já existe');
      return false;
    }
    if (typePeople === 'physical') {
      if (!cpf || sanitizeInput(SanitizeInputType.NUMERIC, cpf).length !== 11) {
        setActiveTab('details');
        setMsgError('Preencha o CPF corretamente');
        setCpfInvalid(true);
        return false;
      }
    } else {
      if (!corporateName) {
        setMsgError('Preencha a razão social corretamente');
        setCorporateNameInvalid(true);
        return false;
      }
      if (
        !cnpj ||
        sanitizeInput(SanitizeInputType.NUMERIC, cnpj).length !== 14
      ) {
        setActiveTab('details');
        setMsgError('Preencha o CNPJ corretamente');
        setCnpjInvalid(true);
        return false;
      }
    }

    if (!zipCode) {
      setActiveTab('details');
      setMsgError('Preencha o CEP corretamente');
      setZipCodeInvalid(true);
      return false;
    }
    if (!address) {
      setActiveTab('details');
      setMsgError('Preencha o endereço corretamente');
      setAddressInvalid(true);
      return false;
    }
    if (!number) {
      setActiveTab('details');
      setMsgError('Preencha o número do endereço corretamente');
      setNumberInvalid(true);
      return false;
    }
    if (!district) {
      setActiveTab('details');
      setMsgError('Preencha o bairro corretamente');
      setDistrictInvalid(true);
      return false;
    }
    if (!city) {
      setActiveTab('details');
      setMsgError('Preencha a cidade corretamente');
      setCityInvalid(true);
      return false;
    }
    if (!state) {
      setActiveTab('details');
      setMsgError('Preencha o estado corretamente');
      setStateInvalid(true);
      return false;
    }

    if (sendBeforeDueDate == 'y' && !beforeDay) {
      setActiveTab('parameters');
      setActiveSideTab('sendDocument');
      setMsgError(
        'Informe os dias para o envio das cobranças antes do vencimento',
      );
      setBeforeDaysInvalid(true);
      return false;
    } else if (
      sendBeforeDueDate == 'y' &&
      sendEmailBeforeDueDate == 'n' &&
      sendWhatsappBeforeDueDate == 'n'
    ) {
      setActiveTab('parameters');
      setActiveSideTab('sendDocument');
      setMsgError(
        'Selecione se deseja o envio de Email e/ou Whatsapp antes do vencimento',
      );
      return false;
    }

    if (
      sendInDueDate == 'y' &&
      sendEmailInDueDate == 'n' &&
      sendWhatsappInDueDate == 'n'
    ) {
      setActiveTab('parameters');
      setActiveSideTab('sendDocument');
      setMsgError(
        'Selecione se deseja o envio de Email e/ou Whatsapp no dia do vencimento',
      );
      return false;
    }

    if (sendAfterDueDate == 'y' && !afterDay) {
      setActiveTab('parameters');
      setActiveSideTab('sendDocument');
      setMsgError(
        'Informe os dias para o envio das cobranças depois do dia do vencimento',
      );
      setAfterDaysInvalid(true);
      return false;
    } else if (
      sendAfterDueDate == 'y' &&
      sendEmailAfterDueDate == 'n' &&
      sendWhatsappAfterDueDate == 'n'
    ) {
      setActiveTab('parameters');
      setActiveSideTab('sendDocument');
      setMsgError(
        'Selecione se deseja o envio de Email e/ou Whatsapp depois do dia do vencimento',
      );
      return false;
    }

    return true;
  }

  async function verifyCnpjExists() {
    try {
      const response = await api.head(
        `companies/check/cnpj?id=${id}&cnpj=${cnpj.replace(/[^0-9]/g, '')}`,
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  function partnersVerify(): boolean {
    if (partners.length === 0) {
      return true;
    }

    for (const partner of partners) {
      if (!partner.name) {
        setActiveTab('partners');
        setIsSubmit(false);
        setMsgError('Preencha o nome do Sócio corretamente');
        setShowModalError(true);
        return false;
      }
      if (
        !partner.cpf ||
        sanitizeInput(SanitizeInputType.NUMERIC, partner.cpf).length !== 11
      ) {
        setActiveTab('partners');
        setIsSubmit(false);
        setMsgError('Preencha o cpf do Sócio corretamente');
        setShowModalError(true);
        return false;
      }
      if (!partner.cell) {
        setActiveTab('partners');
        setIsSubmit(false);
        setMsgError('Preencha o celular do Sócio corretamente');
        setShowModalError(true);
        return false;
      }
    }

    return true;
  }

  async function accountantsVerify(): Promise<boolean> {
    const accountantsAux = accountants;

    if (accountantsAux.length === 0) {
      setActiveTab('accounting');
      setMsgError('Adicione pelo menos um contador da empresa!');
      return false;
    }

    setAccountants(prevState =>
      prevState.map(accountant => {
        return {
          ...accountant,
          nameHasError: false,
          cpfHasError: false,
          cnpjHasError: false,
          emailHasError: false,
          cellHasError: false,
        };
      }),
    );

    for (const accountant of accountantsAux) {
      if (!accountant.name) {
        accountant.nameHasError = true;
        setActiveTab('accounting');
        setMsgError('Preencha o nome do Contador corretamente');
        setAccountants([...accountantsAux]);
        return false;
      }

      if (
        accountant.cpf &&
        sanitizeInput(SanitizeInputType.NUMERIC, accountant.cpf).length !== 11
      ) {
        accountant.cpfHasError = true;
        setActiveTab('accounting');
        setMsgError('CPF do Contador é inválido');
        setAccountants([...accountantsAux]);
        return false;
      }

      if (
        accountant.cnpj &&
        sanitizeInput(SanitizeInputType.NUMERIC, accountant.cnpj).length !== 14
      ) {
        accountant.cnpjHasError = true;
        setActiveTab('accounting');
        setMsgError('CNPJ do Contador é inválido');
        setAccountants([...accountantsAux]);
        return false;
      }

      if (!accountant.email) {
        accountant.emailHasError = true;
        setActiveTab('accounting');
        setMsgError('Preencha o e-mail do Contador corretamente');
        setAccountants([...accountantsAux]);
        return false;
      }

      if (!accountant.cell) {
        accountant.cellHasError = true;
        setActiveTab('accounting');
        setMsgError('Preencha o celular do Contador corretamente');
        setAccountants([...accountantsAux]);
        return false;
      }

      const accountantEmailExists = await UserService.verifyEmailExists(
        accountant.email,
        accountant.userId,
      );
      const foundAccountantByEmailIsNotAccountant =
        accountantEmailExists && accountantEmailExists.isAccountant === 'n';
      const foundAccountantByEmailIsDifferentFromPrevious =
        accountantEmailExists &&
        accountant.userId &&
        accountant.userId !== accountantEmailExists.id;
      if (
        foundAccountantByEmailIsNotAccountant ||
        foundAccountantByEmailIsDifferentFromPrevious
      ) {
        accountant.emailHasError = true;
        setActiveTab('accounting');
        setMsgError(
          'O e-mail do contador informado não pertence à um usuário contador',
        );
        setAccountants([...accountantsAux]);
        return false;
      }

      const accountantCellExists = await UserService.verifyCellExists(
        accountant.cell,
        accountant.userId,
      );
      const foundAccountantByCellIsNotAccountant =
        accountantCellExists && accountantCellExists.isAccountant === 'n';
      const foundAccountantByCellIsDifferentFromPrevious =
        accountantCellExists &&
        accountant.userId &&
        accountant.userId !== accountantCellExists.id;
      if (
        foundAccountantByCellIsNotAccountant ||
        foundAccountantByCellIsDifferentFromPrevious
      ) {
        accountant.cellHasError = true;
        setActiveTab('accounting');
        setMsgError(
          'O celular do contador informado não pertence à um usuário contador',
        );
        setAccountants([...accountantsAux]);
        return false;
      }
    }

    return true;
  }

  async function deletePartners() {
    if (partnersIdsToDelete.length === 0) return;

    for (const idToDelete of partnersIdsToDelete) {
      await api.delete(`companyPartners/${idToDelete}`);
    }
  }

  async function deleteAccountants() {
    if (accountantsIdsToDelete.length === 0) return;

    for (const idToDelete of accountantsIdsToDelete) {
      await api.delete(`companyAccountants/${idToDelete}`);
    }
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />
      <form
        className={'makeStyles-container-12 w-100'}
        onSubmit={evt => onSubmit(evt)}
      >
        <div className="row">
          <div className="col-lg-2 text-center">
            <img
              src={
                logo
                  ? logo
                  : toAbsoluteUrl('/media/logos/header/logo-header-black.png')
              }
              className="img-fluid d-block"
              alt=""
            />
            <Button
              className="mt-3 mb-2"
              variant="primary"
              onClick={handleUploadClick}
            >
              Upload
            </Button>
            <input
              type="file"
              className="d-none"
              ref={imgFileInput}
              onChange={e => handleImgFileChange(e)}
              accept="image/png, image/jpeg"
            />
            {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
          </div>

          <div className="col-lg-3">
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Tipo de pessoa"
              className={classes.textField}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
              value={typePeople}
              disabled={company?.isSuper !== 'y'}
              onChange={e => {
                setTypePeople(e.target.value);
                cleanInputs();
              }}
            >
              <MenuItem
                key="0"
                value="physical"
                onClick={() => setTypePeople('physical')}
              >
                Pessoa Física
              </MenuItem>

              <MenuItem
                key="1"
                value="legal"
                onClick={() => setTypePeople('legal')}
              >
                Pessoa Jurídica
              </MenuItem>
            </TextField>
          </div>

          {typePeople === 'physical' ? (
            <>
              <div className="col-6">
                <TextField
                  label="Nome"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                    setNameInvalid(e.target.value ? false : true);
                  }}
                  error={nameInvalid}
                />
              </div>

              <div className="col-lg-1 d-flex align-items-start justify-content-end">
                <Button
                  type="submit"
                  className="mt-4"
                  variant="primary"
                  disabled={isSubmit}
                >
                  {isSubmit ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />

                      <span className="ml-2">Aguarde...</span>
                    </>
                  ) : (
                    <>
                      <span>Salvar</span>
                    </>
                  )}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="col-3">
                <TextField
                  value={corporateName}
                  label="Razão social"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  onChange={e => {
                    setCorporateName(e.target.value);
                    setCorporateNameInvalid(e.target.value ? false : true);
                  }}
                  error={corporateNameInvalid}
                />
              </div>
              <div className="col-3">
                <TextField
                  value={name}
                  label="Nome fantasia"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  onChange={e => {
                    setName(e.target.value);
                    setNameInvalid(e.target.value ? false : true);
                  }}
                  error={nameInvalid}
                />
              </div>

              <div className="col-lg-1 d-flex align-items-start">
                <Button
                  type="submit"
                  className="mt-4"
                  variant="primary"
                  disabled={isSubmit}
                >
                  {isSubmit ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />

                      <span className="ml-2">Aguarde...</span>
                    </>
                  ) : (
                    <>
                      <span>Salvar</span>
                    </>
                  )}
                </Button>
              </div>
            </>
          )}
        </div>

        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="editcompany-form-tabs"
          className="w-100"
        >
          <Tab eventKey="details" title="Dados da Empresa">
            {typePeople === 'physical' ? (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <PatternFormat
                      label="CPF"
                      required
                      format="###.###.###-##"
                      mask="_"
                      value={cpf}
                      onChange={e => {
                        setCpf(e.target.value);
                        setCpfInvalid(e.target.value ? false : true);
                      }}
                      disabled={company?.isSuper !== 'y'}
                      error={cpfInvalid}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-3">
                    <PatternFormat
                      label="CNPJ"
                      required
                      format="##.###.###/####-##"
                      mask="_"
                      endAdornment={
                        company?.isSuper === 'y' ? (
                          <IconButton
                            edge="end"
                            aria-label="CNPJ search"
                            onClick={() => CheckCnpj(cnpj)}
                          >
                            <i className="flaticon-search"></i>
                          </IconButton>
                        ) : (
                          undefined
                        )
                      }
                      value={cnpj}
                      onChange={e => {
                        setCnpj(e.target.value);
                        setCnpjInvalid(e.target.value ? false : true);
                      }}
                      disabled={company?.isSuper !== 'y'}
                      error={cnpjInvalid}
                    />
                  </div>
                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      label="Inscrição estadual"
                      margin="normal"
                      variant="outlined"
                      value={stateRegistration}
                      onChange={e => {
                        setStateRegistration(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-1 pl-0">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          onChange={e =>
                            setStateRegistration(
                              e.target.checked ? 'ISENTO' : '',
                            )
                          }
                          checked={
                            stateRegistration === 'ISENTO' ? true : false
                          }
                        />
                      }
                      label="Isento"
                      labelPlacement="end"
                      className="ml-1"
                    />
                  </div>
                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      label="Inscrição municipal"
                      margin="normal"
                      variant="outlined"
                      value={municipalRegistration}
                      onChange={e => {
                        setMunicipalRegistration(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      select
                      label="Regime tributário"
                      size="small"
                      SelectProps={{
                        autoWidth: false,
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      variant="outlined"
                      value={taxRegime}
                      onChange={e => setTaxRegime(e.target.value)}
                    >
                      <MenuItem key="1" value="simple national">
                        Simples Nacional
                      </MenuItem>

                      <MenuItem
                        key="2"
                        value="simple national excess revenue"
                        style={{ whiteSpace: 'normal' }}
                      >
                        Simples Nacional - excesso de receita de sublimite de
                        receita bruta
                      </MenuItem>

                      <MenuItem key="3" value="normal regime">
                        Regime Normal
                      </MenuItem>
                    </TextField>
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      size="small"
                      label="CNAE"
                      margin="normal"
                      variant="outlined"
                      value={cnae}
                      onChange={e => {
                        setCnae(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      size="small"
                      label="RNTRC"
                      margin="normal"
                      variant="outlined"
                      value={rntrc}
                      onChange={e => {
                        setRntrc(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row d-flex align-items-center">
              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Responsável"
                  required
                  margin="normal"
                  variant="outlined"
                  value={
                    companyMainUser
                      ? `${companyMainUser.firstname} ${companyMainUser.lastname}`
                      : ''
                  }
                  // onChange={(e) => {
                  //     setResponsible(e.target.value);
                  //     setResponsibleInvalid(e.target.value ? false : true)
                  // }}
                  // error={responsibleInvalid}
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Login do responsável"
                  required
                  margin="normal"
                  variant="outlined"
                  value={companyMainUser?.login ?? ''}
                  onChange={e =>
                    setCompanyMainUser((state: any) => ({
                      ...state,
                      login: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-lg-4">
                <Link
                  to={`/usuarios/${companyMainUser?.id ?? ''}`}
                  className="btn btn-primary"
                >
                  Ver Usuário
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 d-flex justify-content-between align-items-center">
                <PatternFormat
                  className="mr-3"
                  format="#####-###"
                  label="CEP"
                  mask="_"
                  value={zipCode}
                  onChange={e => {
                    setZipCode(e.target.value);
                    setZipCodeInvalid(!e.target.value);
                  }}
                  error={zipCodeInvalid}
                />

                <Button
                  variant="primary"
                  onClick={() => infoAddress()}
                  className="btn-sm"
                >
                  <i className="flaticon-search p-0"></i>
                </Button>
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Endereço"
                  margin="normal"
                  variant="outlined"
                  value={address}
                  onChange={e => {
                    setAddress(e.target.value);
                    setAddressInvalid(!e.target.value);
                  }}
                  error={addressInvalid}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Número"
                  margin="normal"
                  variant="outlined"
                  value={number}
                  onChange={e => {
                    setNumber(e.target.value);
                    setNumberInvalid(!e.target.value);
                  }}
                  error={numberInvalid}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Bairro"
                  margin="normal"
                  variant="outlined"
                  value={district}
                  onChange={e => {
                    setDistrict(e.target.value);
                    setDistrictInvalid(!e.target.value);
                  }}
                  error={districtInvalid}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Complemento"
                  margin="normal"
                  variant="outlined"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Cidade"
                  margin="normal"
                  variant="outlined"
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                    setCityInvalid(!e.target.value);
                  }}
                  error={cityInvalid}
                />
              </div>

              <div className="col-lg-4 pt-3">
                <Autocomplete
                  size="small"
                  value={{ sigla: state }}
                  options={allStates.states}
                  getOptionLabel={({ sigla }) => sigla}
                  style={{ width: '100%', marginTop: '6px' }}
                  onInputChange={(event, newInputValue) => {
                    setState(newInputValue);
                    setStateInvalid(!newInputValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Estado"
                      variant="outlined"
                      error={stateInvalid}
                    />
                  )}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="E-mail"
                  required
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailInvalid(e.target.value ? false : true);
                  }}
                  error={emailInvalid}
                />
              </div>

              <div className="col-lg-4">
                <PatternFormat
                  label="Telefone"
                  className={classes.error}
                  format="(##) ####-####"
                  mask="_"
                  required
                  value={phone}
                  onChange={e => {
                    setPhone(e.target.value);
                    setPhoneInvalid(e.target.value ? false : true);
                  }}
                  error={phoneInvalid}
                />
              </div>

              <div className="col-lg-4">
                <PatternFormat
                  margin="normal"
                  className={classes.error}
                  variant="outlined"
                  format="(##) #####-####"
                  label="Celular"
                  required
                  mask="_"
                  value={cell}
                  onChange={e => {
                    setCell(e.target.value);
                    setCellInvalid(e.target.value ? false : true);
                  }}
                  error={cellInvalid}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Website"
                  margin="normal"
                  variant="outlined"
                  value={website}
                  onChange={e => setWebsite(e.target.value)}
                />
              </div>
            </div>
          </Tab>

          <Tab eventKey="partners" title="Sócios">
            {partners.map((partner, index) => (
              <React.Fragment key={index}>
                <div className="row mt-3">
                  <div className="col d-flex justify-content-end align-items-center">
                    <Button
                      variant="danger"
                      onClick={() => handlePartnerRemove(index, partner.id)}
                    >
                      Excluir
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TextField
                      size="small"
                      label="Nome Completo"
                      required
                      margin="normal"
                      variant="outlined"
                      value={partner.name}
                      onChange={e =>
                        handleSetPartnerField(index, 'name', e.target.value)
                      }
                    />
                  </div>
                  <div className="col">
                    <PatternFormat
                      label="CPF"
                      required
                      format="###.###.###-##"
                      mask="_"
                      value={partner.cpf}
                      onChange={e => {
                        handleSetPartnerField(index, 'cpf', e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <TextField
                      size="small"
                      label="E-mail"
                      className={classes.error}
                      margin="normal"
                      variant="outlined"
                      value={partner.email}
                      onChange={e => {
                        handleSetPartnerField(index, 'email', e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PatternFormat
                      label="Celular"
                      required
                      className={classes.error}
                      format="(##) #####-####"
                      mask="_"
                      value={partner.cell}
                      onChange={e => {
                        handleSetPartnerField(index, 'cell', e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PatternFormat
                      label="Telefone"
                      className={classes.error}
                      format="(##) ####-####"
                      mask="_"
                      value={partner.phone}
                      onChange={e => {
                        handleSetPartnerField(index, 'phone', e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row border-bottom mb-3">
                  <div className="col-lg-4">
                    <TextField
                      type="date"
                      label="Data de nascimento"
                      className={classes.textField}
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partner.birthDate}
                      onChange={e =>
                        handleSetPartnerField(
                          index,
                          'birthDate',
                          e.target.value,
                        )
                      }
                    />
                  </div>

                  <div className="col-lg-4">
                    <TextField
                      size="small"
                      label="RG"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={partner.rg}
                      onChange={e =>
                        handleSetPartnerField(index, 'rg', e.target.value)
                      }
                    />
                  </div>

                  <div className="col-lg-4">
                    <NumericFormat
                      label="Quota (%)"
                      startAdornment="%"
                      value={partner.share}
                      onChange={e =>
                        handleSetPartnerField(index, 'share', e.target.value)
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <div className="row mt-5">
              <div className="col">
                <Button
                  variant="primary"
                  className="mr-3"
                  onClick={handleAddPartnerClick}
                >
                  + Adicionar Sócio
                </Button>
              </div>
            </div>
          </Tab>

          <Tab eventKey="business_hours" title="Horário de Funcionamento">
            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Segunda à Sexta</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      value={onBusinessDay}
                      onChange={e => {
                        setOnBusinessDay(e.target.checked);
                        setBusinessDayOpenHour('');
                        setBusinessDayCloseHour('');
                      }}
                      checked={onBusinessDay}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onBusinessDay && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={businessDayOpenHour}
                      onChange={e => {
                        setBusinessDayOpenHour(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={businessDayCloseHour}
                      onChange={e => {
                        setBusinessDayCloseHour(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Sábado</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      value={onSaturday}
                      onChange={e => {
                        setOnSaturday(e.target.checked);
                        setSaturdayOpenHour('');
                        setSaturdayCloseHour('');
                      }}
                      checked={onSaturday}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onSaturday && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={saturdayOpenHour}
                      onChange={e => {
                        setSaturdayOpenHour(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={saturdayCloseHour}
                      onChange={e => {
                        setSaturdayCloseHour(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Domingo</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      value={onSunday}
                      onChange={e => {
                        setOnSunday(e.target.checked);
                        setSundayOpenHour('');
                        setSundayCloseHour('');
                      }}
                      checked={onSunday}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onSunday && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={sundayOpenHour}
                      onChange={e => {
                        setSundayOpenHour(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={sundayCloseHour}
                      onChange={e => {
                        setSundayCloseHour(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </Tab>

          <Tab eventKey="accounting" title="Contabilidade">
            <div className="row mt-3">
              <div className="col bg-warning-o-30 p-5 mb-3">
                <p>
                  <strong>Atenção: </strong>
                  Adicionar um contador irá criar um usuário com acesso à área
                  de contabilidade da empresa. Um email será enviado ao contador
                  para que ele confirme o acesso.
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                {accountants.map((accountant, index) => (
                  <React.Fragment key={index}>
                    <CompanyFormAccountant
                      accountantId={accountant.id}
                      accountantName={accountant.name}
                      onChangeAccountantName={value =>
                        handleSetAccountantField(index, 'name', value)
                      }
                      accountantCpf={accountant.cpf}
                      onChangeAccountantCpf={value =>
                        handleSetAccountantField(index, 'cpf', value)
                      }
                      accountantCnpj={accountant.cnpj}
                      onChangeAccountantCnpj={value =>
                        handleSetAccountantField(index, 'cnpj', value)
                      }
                      accountantCrc={accountant.crc}
                      onChangeAccountantCrc={value =>
                        handleSetAccountantField(index, 'crc', value)
                      }
                      accountantEmail={accountant.email}
                      onChangeAccountantEmail={value =>
                        handleSetAccountantField(index, 'email', value)
                      }
                      accountantPhone={accountant.phone}
                      onChangeAccountantPhone={value =>
                        handleSetAccountantField(index, 'phone', value)
                      }
                      accountantCell={accountant.cell}
                      onChangeAccountantCell={value =>
                        handleSetAccountantField(index, 'cell', value)
                      }
                      accountantZipCode={accountant.zipCode}
                      onChangeAccountantZipCode={value =>
                        handleSetAccountantField(index, 'zipCode', value)
                      }
                      accountantAddress={accountant.address}
                      onChangeAccountantAddress={value =>
                        handleSetAccountantField(index, 'address', value)
                      }
                      accountantNumber={accountant.number}
                      onChangeAccountantNumber={value =>
                        handleSetAccountantField(index, 'number', value)
                      }
                      accountantDistrict={accountant.district}
                      onChangeAccountantDistrict={value =>
                        handleSetAccountantField(index, 'district', value)
                      }
                      accountantComplement={accountant.complement}
                      onChangeAccountantComplement={value =>
                        handleSetAccountantField(index, 'complement', value)
                      }
                      accountantCity={accountant.city}
                      onChangeAccountantCity={value =>
                        handleSetAccountantField(index, 'city', value)
                      }
                      accountantState={accountant.state}
                      onChangeAccountantState={value =>
                        handleSetAccountantField(index, 'state', value)
                      }
                      accountantNameHasError={accountant.nameHasError}
                      accountantEmailHasError={accountant.emailHasError}
                      accountantCellHasError={accountant.cellHasError}
                      accountantCpfHasError={accountant.cpfHasError}
                      accountantCnpjHasError={accountant.cnpjHasError}
                      allStates={allStates}
                      CheckCnpj={value => CheckCnpjAccountant(value, index)}
                      infoAddress={value => infoAddressAccountant(value, index)}
                      currentIndex={index}
                      onAccountantRemove={handleAccountantRemove}
                    />
                    <hr />
                  </React.Fragment>
                ))}
                <div className="row mt-5">
                  <div className="col">
                    <Button
                      variant="primary"
                      className="mr-3"
                      onClick={handleAddAccountantClick}
                    >
                      + Adicionar Contador
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="parameters" title="Parâmetros">
            <Tab.Container
              activeKey={activeSideTab}
              onSelect={(tab: string) => setActiveSideTab(tab)}
            >
              <div className="row mt-3">
                <div className="col-md-3">
                  <Nav variant="tabs" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="sendDocument" href="#">
                        Cobrança
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fiscalIssuance" href="#">
                        Emissão
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link disabled={true} eventKey="" href="#">
                        Vendas
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nfe" href="#">
                        - NF-e
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nfce" href="#">
                        - NFC-e
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="pdv" href="#">
                        - PDV
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link disabled={true} eventKey="" href="#">
                        Serviços
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nfse" href="#">
                        - NFS-e
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="status" href="#">
                        Status
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="financial" href="#">
                        Financeiro
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="optics" href="#">
                        Ótica
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-9">
                  <Tab.Content>
                    <Tab.Pane eventKey="sendDocument">
                      <SendDocument
                        sendBeforeDueDate={sendBeforeDueDate}
                        sendInDueDate={sendInDueDate}
                        sendAfterDueDate={sendAfterDueDate}
                        beforeDay={beforeDay}
                        afterDay={afterDay}
                        setSendBeforeDueDate={setSendBeforeDueDate}
                        setSendInDueDate={setSendInDueDate}
                        setSendAfterDueDate={setSendAfterDueDate}
                        setBeforeDay={setBeforeDay}
                        setAfterDay={setAfterDay}
                        sendEmailBeforeDueDate={sendEmailBeforeDueDate}
                        setSendEmailBeforeDueDate={setSendEmailBeforeDueDate}
                        sendWhatsappBeforeDueDate={sendWhatsappBeforeDueDate}
                        setSendWhatsappBeforeDueDate={
                          setSendWhatsappBeforeDueDate
                        }
                        sendEmailInDueDate={sendEmailInDueDate}
                        setSendEmailInDueDate={setSendEmailInDueDate}
                        sendWhatsappInDueDate={sendWhatsappInDueDate}
                        setSendWhatsappInDueDate={setSendWhatsappInDueDate}
                        sendEmailAfterDueDate={sendEmailAfterDueDate}
                        setSendEmailAfterDueDate={setSendEmailAfterDueDate}
                        sendWhatsappAfterDueDate={sendWhatsappAfterDueDate}
                        setSendWhatsappAfterDueDate={
                          setSendWhatsappAfterDueDate
                        }
                        beforeDaysInvalid={beforeDaysInvalid}
                        setBeforeDaysInvalid={setBeforeDaysInvalid}
                        afterDaysInvalid={afterDaysInvalid}
                        setAfterDaysInvalid={setAfterDaysInvalid}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fiscalIssuance">
                      <ConfigFiscalIssuance
                        companyId={id}
                        setEnvMode={setEnvMode}
                        envMode={envMode}
                        apiConfig={nfApiConfig}
                        savedCertificate={savedCertificate}
                        setSavedCertificate={setSavedCertificate}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="nfe">
                      <ConfigNfe
                        companyId={id}
                        data={nfeConfigData}
                        apiConfig={nfApiConfig}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="nfce">
                      <ConfigNfce
                        companyId={id}
                        data={nfceConfigData}
                        apiConfig={nfApiConfig}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="pdv">
                      <ConfigPdv
                        dreSubCategoryId={pdvDreSubCategoryId}
                        setDreSubCategoryId={setPdvDreSubCategoryId}
                        stockLocationId={pdvStockLocationId}
                        setStockLocationId={setPdvStockLocationId}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="nfse">
                      <ConfigNfse
                        companyId={id}
                        data={nfseConfigData}
                        apiConfig={nfApiConfig}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="status">
                      <ManageStatus />
                    </Tab.Pane>
                    <Tab.Pane eventKey="financial">
                      <ConfigFinancial
                        companyId={id}
                        blockDayForChangeOfBillsSituationOfPastMonth={
                          blockDayForChangeOfBillsSituationOfPastMonth
                        }
                        setBlockDayForChangeOfBillsSituationOfPastMonth={
                          setBlockDayForChangeOfBillsSituationOfPastMonth
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="optics">
                      <ConfigOptics
                        templateOpticsMessage={templateOpticsMessage}
                        setTemplateOpticsMessage={setTemplateOpticsMessage}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Tab>
          <Tab eventKey="logs" title="Log" className="w-100">
            <ListLogs />
          </Tab>
        </Tabs>

        <div className="row">
          <div className="col-lg-11"></div>

          <div className="col-lg-1 d-flex align-items-start justify-content-end">
            <Button
              type="submit"
              className="mt-4"
              variant="primary"
              disabled={isSubmit}
            >
              {isSubmit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ml-2">Aguarde...</span>
                </>
              ) : (
                <>
                  <span>Salvar</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
