import { formatToFloat } from "../utils/formatCurrency";
import api from "./Api";

export type Product = {
  id: number;
  name: string;
  code: string;
  saleValue: number;
  codeGtinEan: string;
  stockInitial: string;
  currentStock: number;
  hasVariation: string;
  isKit: string;
  kit: string;
  situation?: string;
  brand?: string;
  category?: string;
  type: "product" | "service" | "variation" | "kit";
  originalProductId?: number;
  ncm?: string;
  ipiRate?: string;
  icmsRate?: string;
};

class ProductService {
  async getProductById(id: number | string) {
    const { data } = await api.get(`products/${id || "0"}`);
    return data;
  }

  async getAllProductsFiltered(filters: any) {
    const productsResponse = await api.get<any[]>("products", {
      params: {
        filters: { ...filters },
      },
    });

    const products = productsResponse.data.map((prod) => ({
      ...prod,
      saleValue: formatToFloat(prod.saleValue),
    }));

    return products;
  }

  async getProductsWithoutVariationsFiltered(
    filters: any,
    includeServices: boolean = false
  ) {
    const productsResponse = await api.get<any[]>("products", {
      params: {
        filters: { ...filters, type: includeServices ? "both" : "product" },
      },
    });

    const products = productsResponse.data.map((prod) => ({
      ...prod,
      saleValue: formatToFloat(prod.saleValue),
    }));
    const rawProducts = products.filter((prod) => prod.hasVariation === "n");

    return rawProducts;
  }

  async getProductsAndVariationsFiltered(
    filters: any,
    includeServices: boolean = false
  ) {
    const productsResponse = await api.get<any[]>("products", {
      params: {
        filters: { ...filters, type: includeServices ? "both" : "product" },
      },
    });

    const products = productsResponse.data.map((prod) => ({
      ...prod,
      saleValue: formatToFloat(prod.saleValue),
    }));
    const rawProducts = products.filter(
      (prod) => prod.hasVariation === "n" && prod.isKit === "n"
    );

    const productsAndVariations: Product[] = [...rawProducts];

    for (const product of products) {
      // Adicionando variações no array de produtos
      if (
        product.hasVariation === "y" &&
        product.variations &&
        product.variations.length > 0
      ) {
        for (const variation of product.variations) {
          productsAndVariations.push({
            id: variation.id,
            name: variation.name,
            code: variation.code,
            saleValue: formatToFloat(variation.saleValue),
            codeGtinEan: variation.codeGtinEan,
            stockInitial: variation.stockInitial,
            currentStock: Number(variation.currentStock),
            hasVariation: "y",
            isKit: "n",
            kit: "",
            type: "variation",
            situation: variation.situation,
            brand: variation.brand,
            category: variation.category,
            originalProductId: variation.productId,
          });
        }
      }

      // Mapeando de adicionando os kits
      if (product.isKit === "y" && product.kit) {
        const productsKit = JSON.parse(product.kit);

        let nameProducts = "";
        for (const kitProduct of productsKit) {
          nameProducts += !nameProducts.length
            ? kitProduct.productName
            : ", " + kitProduct.productName;
        }

        productsAndVariations.push({
          id: product.id,
          name: product.name,
          code: product.code,
          saleValue: product.saleValue,
          codeGtinEan: product.codeGtinEan,
          stockInitial: product.stockInitial,
          currentStock: Number(product.currentStock),
          hasVariation: "n",
          isKit: "y",
          kit: nameProducts,
          type: "kit",
          situation: product.situation,
          brand: product.brand,
          category: product.category,
        });
      }
    }

    return productsAndVariations;
  }

  async getProductsAndVariationsGtinEanFiltered(
    filters: any,
    includeServices: boolean = false
  ) {
    if (!isNaN(filters.name) && !isNaN(parseFloat(filters.name))) {
      const gtinEan = filters.name;
      let productResponse = await api.get<any>(
        `/products/codeGtinEan/${gtinEan}`
      );

      const variationResponse = await api.get<any>(
        `/variations/codeGtinEan/${gtinEan}`
      );

      if (productResponse.data === "" && variationResponse.data === "") {
        productResponse = await api.get<any>(`/products/${gtinEan}`);
      }

      const productsAndVariations: Product[] = [];

      if (productResponse.data === "" && variationResponse.data === "")
        return productsAndVariations;

      if (productResponse.data) {
        productsAndVariations.push({
          id: productResponse.data.id,
          name: productResponse.data.name,
          code: productResponse.data.code,
          saleValue: productResponse.data.saleValue,
          codeGtinEan: productResponse.data.codeGtinEan,
          stockInitial: productResponse.data.stockInitial,
          currentStock: Number(productResponse.data.currentStock),
          hasVariation: productResponse.data.hasVariation,
          isKit: productResponse.data.isKit,
          kit: productResponse.data.kit,
          type: productResponse.data.type,
          situation: productResponse.data.situation,
          brand: productResponse.data.brand,
          category: productResponse.data.category,
        });
      }

      if (variationResponse.data) {
        productsAndVariations.push({
          id: variationResponse.data.id,
          name: variationResponse.data.name,
          code: variationResponse.data.code,
          saleValue: variationResponse.data.saleValue,
          codeGtinEan: variationResponse.data.codeGtinEan,
          stockInitial: variationResponse.data.stockInitial,
          currentStock: Number(variationResponse.data.currentStock),
          hasVariation: variationResponse.data.hasVariation,
          isKit: variationResponse.data.isKit,
          kit: variationResponse.data.kit,
          type: variationResponse.data.type,
          situation: variationResponse.data.situation,
          brand: variationResponse.data.brand,
          category: variationResponse.data.category,
        });
      }

      return productsAndVariations;
    } else {
      const productsResponse = await api.get<any[]>("products", {
        params: {
          filters: { ...filters, type: includeServices ? "both" : "product" },
        },
      });

      const products = productsResponse.data.map((prod) => ({
        ...prod,
        saleValue: formatToFloat(prod.saleValue),
      }));
      const rawProducts = products.filter(
        (prod) => prod.hasVariation === "n" && prod.isKit === "n"
      );

      const productsAndVariations: Product[] = [...rawProducts];

      for (const product of products) {
        // Adicionando variações no array de produtos
        if (
          product.hasVariation === "y" &&
          product.variations &&
          product.variations.length > 0
        ) {
          for (const variation of product.variations) {
            productsAndVariations.push({
              id: variation.id,
              name: variation.name,
              code: variation.code,
              saleValue: formatToFloat(variation.saleValue),
              codeGtinEan: variation.codeGtinEan,
              stockInitial: variation.stockInitial,
              currentStock: Number(variation.currentStock),
              hasVariation: "y",
              isKit: "n",
              kit: "",
              type: "variation",
              situation: variation.situation,
              brand: variation.brand,
              category: variation.category,
              originalProductId: variation.productId,
            });
          }
        }

        // Mapeando de adicionando os kits
        if (product.isKit === "y" && product.kit) {
          const productsKit = JSON.parse(product.kit);

          let nameProducts = "";
          for (const kitProduct of productsKit) {
            nameProducts += !nameProducts.length
              ? kitProduct.productName
              : ", " + kitProduct.productName;
          }

          productsAndVariations.push({
            id: product.id,
            name: product.name,
            code: product.code,
            saleValue: product.saleValue,
            codeGtinEan: product.codeGtinEan,
            stockInitial: product.stockInitial,
            currentStock: Number(product.currentStock),
            hasVariation: "n",
            isKit: "y",
            kit: nameProducts,
            type: "kit",
            situation: product.situation,
            brand: product.brand,
            category: product.category,
          });
        }
      }

      return productsAndVariations;
    }
  }

  async getServicesFiltered(filters: any) {
    const productsResponse = await api.get<any[]>("products", {
      params: { filters: { ...filters, type: "service" } },
    });

    const services = productsResponse.data.map((service) => ({
      ...service,
      saleValue: formatToFloat(service.saleValue),
    }));

    return services;
  }
}

export default new ProductService();
