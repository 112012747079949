import React, { useState, useLayoutEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { Search } from "../../components/Search";
import {
  BodyDataBaseProps,
  HeadDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import api from "../../services/Api";

type Filters = {
  searchQuery: string;
};

function ListContractTemplate() {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [triggerLoad, setTriggerLoad] = useState(false);
  const [countTotalTemplates, setCountTotalTemplates] = useState(0);
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [data, setData] = useState<{ contractTemplate: any[] }>({
    contractTemplate: [],
  });
  const filtersRef = useRef<Filters | null>(null);
  const { user } = useSelector((state: any) => state.auth);

  const [countTotalRecords, setCountTotalRecords] = useState(0);

  const headData: HeadDataBaseProps[] = [
    { value: "Código", reference: "id" },
    { value: "Nome", reference: "name" },
    { value: "Descrição", reference: "description" },
  ];

  useLayoutEffect(() => {
    const list: BodyDataBaseProps[][] = [];
    data.contractTemplate.forEach(({ id, name, description }) => {
      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(id), id: true },
        { for: "name", value: name },
        { for: "description", value: description },
      ];

      list.push(data);
    });

    setBodyData(list);
  }, [data]);
  const handleClickAdd = () => {
    history.push("/modelo-contrato/adicionar");
  };

  const handleClickEdit = useCallback((id: string) => {
    history.push(`/modelo-contrato/${id}`);
  }, []);

  const handleClickDelete = useCallback(async (id: string) => {
    try {
      await api.delete(`modelo-contrato/${id}`);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClickSearch = () => {
    setTriggerLoad(!triggerLoad);
  };

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await api.get<{ rows: any[]; count: number }>(
        "contract-templates",
        {
          params: {
            skip: rowsPerPage * currentPage,
            take: rowsPerPage,
            filters: filtersRef.current
              ? JSON.stringify(filtersRef.current)
              : undefined,
            sortReference,
            sortDirection,
          },
        }
      );

      const { rows, count } = data;

      setData({ contractTemplate: rows });
      setCountTotalRecords(count);
    },
    []
  );

  return (
    <div className="card card-body pt-4">
      <div className="row d-flex align-items-center">
        <div className="col-lg-9 mt-3">
          {user.isAccountant === "n" && (
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={handleClickAdd}
            >
              Adicionar Modelo de Contrato
            </Button>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>

      <div className="mt-3">
        <ListWithModalChangeSituation
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
          sortable={true}
          loadData={loadData}
          totalCount={countTotalTemplates}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
        />
      </div>
    </div>
  );
}

export default ListContractTemplate;
