import React, { useCallback, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import "../../../style.css";
import logService from "../../../services/LogService";
import ModalSeeLog from "../../../components/ModalSeeLog";
import { orderByIdDesc } from "../../../utils/orderTable";
import api from "../../../services/Api";
import { yesOrNo } from "../../../types/yesOrNo";
import { Autocomplete } from "@material-ui/lab";
import ApiResourceSelect from "../../../components/ApiResourceSelect";
import UserService from "../../../services/UserService";

type tableRowslog = {
  id: number;
  userId: number;
  userName: string;
  module: string;
  edit: string;
  editText: string;
  itemCreated: string;
  itemEdited: string;
  itemName: string;
  lastValue: string;
  actualValue: string;
  field: string;
  createdDate: string;
};

export function ListLogs() {
  const [allLogs, setAllLogs] = useState({ allLogs: [] });
  const [tableRowslog, settableRowslog] = useState<tableRowslog[]>([]);

  const [showModalLog, setShowModalLog] = useState(false);
  const [justLastValues, setJustLastValues] = useState<any>();
  const [justActualValues, setJustActualValues] = useState<any>();
  const [logId, setLogId] = useState<any>();

  const [isLoadingLogs, setIsLoadingLogs] = useState(true);
  const [countLogs, setCountLogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [modulesInput, setModulesInput] = useState([]);

  const [isSearching, setIsSearching] = useState(false);
  const [user, setUser] = useState<any>("all");
  const [items, setItems] = useState("");
  const [modules, setModules] = useState<any>("all");
  const [types, setTypes] = useState("all");
  const [dateMin, setDateMin] = useState<any>("");
  const [dateMax, setDateMax] = useState<any>("");

  useEffect(() => {
    getModuleInput();
  }, []);

  useEffect(() => {
    getLogs();
  }, [rowsPerPage, currentPage]);

  async function getLogs() {
    setIsLoadingLogs(true);

    const userId =
      user !== "all" && user !== undefined && user !== null ? user : "all";

    var response = await api.get("/log", {
      params: {
        skip: rowsPerPage * currentPage,
        take: rowsPerPage,
        filterUser: userId,
        filterModules: modules,
        filterItems: items,
        filterTypes: types,
        filterDateMin: dateMin,
        filterDateMax: dateMax,
      },
    });

    setAllLogs({ allLogs: response.data.rows.sort(orderByIdDesc) });
    setCountLogs(response.data.count);

    setIsLoadingLogs(false);
  }

  useEffect(() => {
    async function createTable() {
      const aux = allLogs.allLogs;
      const rows: any[] = await Promise.all(
        aux.map(async (log) => {
          var createdFormatted = new Date(log["createdDate"]).toLocaleString(
            "pt-BR"
          );
          return {
            id: log["id"],
            userId: log["userId"],
            userName: log["userName"],
            module: log["module"],
            edit: log["edit"],
            editText: log["edit"] === yesOrNo.YES ? "Edição" : "Criação",
            itemCreated: log["itemCreated"],
            itemEdited: log["itemEdited"],
            itemName: `${
              log["edit"] === yesOrNo.YES
                ? log["itemEdited"]
                : log["itemCreated"]
            } - ${log["itemId"]}`,
            lastValue: log["lastValue"],
            actualValue: log["actualValue"],
            field: log["field"],
            createdDate: createdFormatted,
          };
        })
      );
      settableRowslog(rows);
    }

    createTable();
  }, [allLogs]);

  function handleOpenLog(id: string | number) {
    setShowModalLog(true);
    setLogId(id);

    const [last] = tableRowslog.filter((item: any) => item.id === id);
    setJustLastValues(logService.formatDescriptionValues(last.lastValue));

    const [actual] = tableRowslog.filter((item: any) => item.id === id);
    setJustActualValues(logService.formatDescriptionValues(actual.actualValue));
  }

  const handleChangePage = useCallback(async (next: number) => {
    setAllLogs({ allLogs: [] });
    setCurrentPage(next);
  }, []);

  const handleRowsPerPage = useCallback(async (value: number) => {
    setAllLogs({ allLogs: [] });
    setRowsPerPage(value);
  }, []);

  async function handleClickSearch() {
    setIsSearching(true);

    try {
      getLogs();
    } catch (err) {
      console.log(err);
    }

    setIsSearching(false);
  }

  const clearSearch = () => {
    setUser("all");
    setModules("all");
    setTypes("all");
    setItems("");
    setDateMin("");
    setDateMax("");
  };

  async function getModuleInput() {
    let dataModules: any = [];

    try {
      const res = await api.get<any>("/menus");

      for (let item of res.data) {
        if (item.route !== null) {
          dataModules.push({
            name: item.name,
            id: item.id,
          });
        }
      }

      setModulesInput(dataModules);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="row d-flex align-items-center">
        <div className="col-lg-3">
          <ApiResourceSelect
            label="Usuário"
            noOptionsText="Nenhum usuário encontrado"
            getOptionLabel={(option: any) =>
              UserService.getFullName(option, "")
            }
            value={user}
            onSelect={(option) => setUser(String(option?.id ?? "all"))}
            apiSearchHandler={(typedText) =>
              UserService.getAllUsersFiltered({ name: typedText })
            }
            getSelectedOption={(loadedOptions) => {
              if (!user || user === "all") return null;
              return (
                loadedOptions.find((option) => option.id === Number(user)) ??
                UserService.getUserById(user)
              );
            }}
          />
        </div>

        <div className="col-lg-3">
          <Autocomplete
            size="small"
            noOptionsText="Carregando Módulos... "
            getOptionLabel={(modules: any) => modules.name}
            options={modulesInput}
            value={
              modulesInput.find(({ name }: any) => name === modules) ?? null
            }
            onInputChange={(e, value) => setModules(value)}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Módulos"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </div>

        <div className="col-lg-3">
          <TextField
            select
            size="small"
            label="Tipo"
            margin="normal"
            variant="outlined"
            value={types}
          >
            <MenuItem key="0" value="all" onClick={() => setTypes("all")}>
              Todos
            </MenuItem>

            <MenuItem key="1" value="n" onClick={() => setTypes("n")}>
              Criação
            </MenuItem>

            <MenuItem key="2" value="y" onClick={() => setTypes("y")}>
              Edição
            </MenuItem>
          </TextField>
        </div>

        <div className="col-lg-3">
          <TextField
            size="small"
            label="Item"
            margin="normal"
            variant="outlined"
            value={items}
            onChange={(e) => setItems(e.target.value)}
          />
        </div>

        <div className="col-lg-5 d-flex justify-content-between">
          <TextField
            type="datetime-local"
            label="Data Min."
            margin="normal"
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={dateMin}
            onChange={(e: any) => setDateMin(e.target.value)}
            className="mr-5"
          />

          <TextField
            type="datetime-local"
            label="Data Max."
            margin="normal"
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={dateMax}
            onChange={(e: any) => setDateMax(e.target.value)}
          />
        </div>

        <div className="col-12 d-flex justify-content-end">
          <Button
            type="button"
            variant="primary"
            disabled={isSearching}
            className="mx-2"
            onClick={handleClickSearch}
          >
            {isSearching ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="situation"
                  aria-hidden="true"
                />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Pesquisar</span>
              </>
            )}
          </Button>
          <Button variant="secondary" onClick={clearSearch}>
            Limpar
          </Button>
        </div>
      </div>

      <TableContainer style={{ maxHeight: "75vh" }}>
        <Table stickyHeader className="wrap">
          <TableHead>
            <TableRow>
              <TableCell>Usúario</TableCell>
              <TableCell>Modulo</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Anterior</TableCell>
              <TableCell>Editado</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingLogs && (
              <TableRow>
                <TableCell colSpan={7}>
                  <div className="d-flex justify-content-center">
                    <Spinner
                      as="span"
                      variant="primary"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}

            {!isLoadingLogs &&
              tableRowslog.map((row: any, index: any) => (
                <TableRow key={index}>
                  <TableCell>{row.userName}</TableCell>
                  <TableCell>{row.module}</TableCell>
                  <TableCell>{row.editText}</TableCell>
                  <TableCell>{row.itemName}</TableCell>
                  <TableCell>
                    {logService.formatEditedValues(row.lastValue).length >
                    200 ? (
                      <>
                        {logService
                          .formatEditedValues(row.lastValue)
                          .substring(0, 140) + "..."}
                        <span
                          onClick={() => handleOpenLog(row.id)}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          {" "}
                          Ver mais
                        </span>
                      </>
                    ) : (
                      logService.formatEditedValues(row.lastValue)
                    )}
                  </TableCell>
                  <TableCell>
                    {logService.formatEditedValues(row.actualValue).length >
                    160 ? (
                      <>
                        {logService
                          .formatEditedValues(row.actualValue)
                          .substring(0, 160) + "..."}
                        <span
                          onClick={() => handleOpenLog(row.id)}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          Ver mais
                        </span>
                      </>
                    ) : (
                      logService.formatEditedValues(row.actualValue)
                    )}
                  </TableCell>
                  <TableCell>{row.createdDate}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage="Linhas por página"
        page={currentPage}
        component="div"
        count={countLogs}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        backIconButtonProps={{
          "aria-label": "Página Anterior",
        }}
        nextIconButtonProps={{
          "aria-label": "Próxima Página",
        }}
        onChangePage={(_, next) => handleChangePage(next)}
        onChangeRowsPerPage={(evt) =>
          handleRowsPerPage(Number(evt.target.value))
        }
      />

      <ModalSeeLog
        showSeeLog={showModalLog}
        setShowModalLog={setShowModalLog}
        contentLastValues={justLastValues}
        contentActualValues={justActualValues}
        contentId={logId!}
      />
    </>
  );
}
