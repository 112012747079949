import { limitCreditPeriodicity } from "../types/Customer";
import { CustomerLimitCreditInformation } from "../types/CustomerLimitCreditInformation";
import api from "./Api";

type GetCustomerOptions = {
  withMainAddress?: boolean;
};

class CustomerService {
  async getCustomersFiltered(filters: any) {
    const customerResponse = await api.get<any[]>("customer", {
      params: { filters },
    });

    const customers = customerResponse.data.map((customer) => ({
      ...customer,
      name: this.getCustomerName(customer),
    }));

    return customers;
  }

  getNameByIdSync(id: number, customers: any[]): string | undefined {
    const foundCustomer = customers.find(
      (customerObj) => Number(customerObj.id) === id
    );
    return this.getCustomerName(foundCustomer);
  }

  async getNameByIdAsync(id: number | string): Promise<string> {
    const foundCustomerResponse = await api.get(`customer/${id || "0"}`);
    const foundCustomer = foundCustomerResponse.data;
    return this.getCustomerName(foundCustomer);
  }

  async getCustomerById(id: number | string, options: GetCustomerOptions = {}) {
    const { data } = await api.get(`customer/${id || "0"}`, {
      params: options,
    });
    if (!data) return null;

    data.name = this.getCustomerName(data);
    return data;
  }

  async getCustomerLimitCreditInformation(customerId: number | string) {
    const response = await api.get<CustomerLimitCreditInformation>(
      `customer/limitCredit/${customerId || "0"}`
    );

    return response.data || null;
  }

  getCustomerName(customer?: any, defaultValue: string = "") {
    if (!customer || typeof customer !== "object") {
      return defaultValue ? defaultValue : "";
    }

    const name =
      customer.typePeople === "legal" ? customer.corporateName : customer.name;

    return name || "";
  }

  getCustomerNameWithId(customer?: any, defaultValue: string = "") {
    if (!customer || typeof customer !== "object") {
      return defaultValue ? defaultValue : "";
    }

    const name = this.getCustomerName(customer);
    const nameWithId = name ? `${customer.id} - ${name}` : "";

    return nameWithId;
  }

  getTypeRegisterText(typeRegister: string) {
    switch (typeRegister) {
      case "customer":
        return "Cliente";
      case "supplier":
        return "Fornecedor";
      case "both":
        return "Ambos";

      default:
        return "";
    }
  }

  getMaritalStatusText(maritalStatus: string) {
    switch (maritalStatus) {
      case "unmarried":
        return "Solteiro";
      case "married":
        return "Casado";
      case "widower":
        return "Viúvo";
      case "separate":
        return "Separado";
      case "divorced":
        return "Divorciado";
      case "other":
        return "Outro";

      default:
        return "";
    }
  }

  getLimitCreditPeriodicityText(value: limitCreditPeriodicity) {
    switch (value) {
      case limitCreditPeriodicity.MONTHLY:
        return "Mensal";
      case limitCreditPeriodicity.BIMONTHLY:
        return "Bimestral";
      case limitCreditPeriodicity.TRIMONTHLY:
        return "Trimestral";
      case limitCreditPeriodicity.QUARTERLY:
        return "Quadrimestral";
      case limitCreditPeriodicity.SEMIANNUAL:
        return "Semestral";
      case limitCreditPeriodicity.ANNUAL:
        return "Anual";

      default:
        return "";
    }
  }
}

export default new CustomerService();
