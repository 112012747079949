import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import { Button, Tab, Tabs, Spinner } from "react-bootstrap";

import "../../style.css";

import api from "../../services/Api";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from "../../utils/paymentOptions";
import { NumericFormat } from "../../components/NumericFormat";
import ModalError from "../../components/ModalError";
import {
  dateIsBetweenRange,
  extractDateStringFromTimestamp,
} from "../../utils/dateTimeHelper";
import LogService from "../../services/LogService";
import { SubCategory } from "../../types/Dre";
import { BillsToPay } from "../../types/BillsToPay";
import SellerService from "../../services/SellerService";
import ApiResourceSelect from "../../components/ApiResourceSelect";
import CustomerService from "../../services/CustomerService";
import BankAccountService from "../../services/BankAccountService";
import { CenterCost } from "../../types/CenterCost";
import CenterCostService from "../../services/CenterCostService";
import DreCategoryService from "../../services/DreCategoryService";
import useAddAccountBank from "../../hooks/addAccountBank";
import { ApiResourceSelectAddButton } from "../../components/ApiResourceSelectAddButton";
import { AddAccountBankModal } from "../../components/AccountBank/AddAccountBankModal";
import { NewCustomerModal } from "../../components/Customer/NewCustomerModal";
import useNewCustomerForm from "../../hooks/newCustomerForm";
import { Customer } from "../../types/Customer";
import { uploadMultipleFiles } from "../../utils/FilesHelper";
import UploadFiles from "../../components/UploadFiles";
import { useSelector } from "react-redux";
import CompanySelect from "../../components/CompanySelect";

type BankAccount = {
  id: number;
  name: string;
  nameBank: string;
};

type Company = {
  id: number;
  name: string;
};

type Beneficiary = {
  id: number;
  name: string;
  cpf: string;
  cnpj: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export function NewBillsToPay() {
  const { user } = useSelector((state: any) => state.auth);

  const [activeTab, setActiveTab] = useState("detalhes-despesa");

  const [name, setName] = useState("");
  const [payment, setPayment] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [comments, setComments] = useState("");
  const [barCode, setBarCode] = useState("");
  const [amount, setAmount] = useState("0");
  const [isPaid, setIsPaid] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isInstallment, setIsInstallment] = useState(false);
  const [isGenerateInstallment, setIsGenerateInstallment] = useState(false);
  const [occurrence, setOccurrence] = useState("unica");
  const [accountBank, setAccountBank] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(user.companyId);
  const [centerCost, setCenterCost] = useState("");
  const [beneficiaryType, setBeneficiaryType] = useState("supplier");
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);

  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  // Inputs Verify
  const [expensesError, setExpensesError] = useState(false);
  const [accountBankError, setAccountBankError] = useState(false);
  const [payMethodsError, setPayMethodsError] = useState(false);
  const [dueError, setDueError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [accountPlanError, setAccountPlanError] = useState(false);
  const [issueDateError, setIssueDateError] = useState(false);
  const [filesSelected, setFilesSelected] = useState<File[] | null>(null);
  const [filesNamesSaved, setFilesNamesSaved] = useState<string[]>([]);
  const [installments, setInstallments] = useState({
    installments: [
      {
        date: "",
        value: 0,
        typePayment: "",
      },
    ],
  });

  const { idToClone } = useParams<{ idToClone: string }>();
  const classes = useStyles();
  const history = useHistory();
  const subHeader = useSubheader();
  const { register, watch, handleSubmit } = useForm();

  subHeader.setTitle("Adicionar Conta a Pagar");

  // Modal AddAccountBank
  const {
    showModalAddAccountBank,
    setShowModalAddAccountBank,
  } = useAddAccountBank();
  const handleCreateAccountBank = (accountBank: BankAccount) => {
    setAccountBank(String(accountBank.id));
  };

  // Modal New Customer
  const {
    showModalNewCustomer,
    setShowModalNewCustomer,
    newCustomerDefaultData,
    setNewCustomerDefaultData,
  } = useNewCustomerForm();

  const handleCreateCustomer = (createdCustomer: Customer) => {
    setBeneficiaryId(String(createdCustomer.id));
  };

  const handleClickAddCustomer = (typedText: string) => {
    setNewCustomerDefaultData({ name: typedText, typeRegister: "supplier" });
    setShowModalNewCustomer(true);
  };

  useEffect(() => {
    async function getDefaultAccountBank() {
      if (idToClone) {
        return;
      }

      const {
        standardExpenseAccount,
      } = await BankAccountService.getDefaultBankAccounts();
      setAccountBank(String(standardExpenseAccount?.id ?? ""));
    }

    getDefaultAccountBank();
    setIssueDate(extractDateStringFromTimestamp());
  }, [idToClone]);

  useEffect(() => {
    if (!idToClone) return;

    async function getBillToCloneData() {
      const { data: billToClone } = await api.get<BillsToPay>(
        `billsToPay/${idToClone}`
      );

      if (!billToClone) return;

      setName(String(billToClone.name ?? ""));
      setAmount(formatCurrency(billToClone.amount));
      setIsPaid(billToClone.status == "paid" ? true : false);
      setBarCode(String(billToClone.barCode ?? ""));
      setPayment(String(billToClone.payment ?? ""));
      setDueDate(String(billToClone.dueDate ?? ""));
      setComments(String(billToClone.comments ?? ""));
      setBeneficiaryType(billToClone.beneficiaryType);
      setBeneficiaryId(
        billToClone.beneficiaryType === "supplier"
          ? String(billToClone.supplier ?? "")
          : String(billToClone.seller ?? "")
      );
      setIssueDate(String(billToClone.issueDate ?? ""));
      setDocNumber(String(billToClone.docNumber ?? ""));
      setCenterCost(String(billToClone.centerCost ?? ""));
      setAccountBank(String(billToClone.bankAccount ?? ""));
      setDreSubCategoryId(billToClone.dreSubCategoryId ?? 0);
    }

    getBillToCloneData();
  }, [idToClone]);

  const onSubmit = async (dataForm: any) => {
    try {
      setIsSubmit(true);

      if (!inputsVerify(dataForm)) return;

      let filesPath: any = [];
      if (filesSelected) {
        filesPath = await uploadMultipleFiles(
          filesSelected,
          setShowModalError,
          setMsgError,
          setIsSubmit
        );
        if (filesPath === false) {
          return;
        }
      }

      const bank = await api.get(`/accountBank/${parseInt(accountBank)}`);
      const today = new Date().toISOString().split("T")[0];

      if (occurrence == "parcelada") {
        var countInstallments = parseInt(watch("installments"));

        for (
          var indexSubmit = 0;
          indexSubmit < countInstallments;
          indexSubmit++
        ) {
          var allInstallments = installments.installments;

          var data = {
            name:
              name + " - Parc. - " + ` ${indexSubmit + 1}/${countInstallments}`,
            amount: allInstallments[indexSubmit].value,
            remaining: allInstallments[indexSubmit].value,
            status: isPaid ? "paid" : "pending",
            bankAccount:
              String(accountBank).length > 0 ? parseInt(accountBank) : null,
            nameBank:
              String(accountBank).length > 0 ? bank.data.nameBank : null,
            centerCost:
              String(centerCost).length > 0 ? parseInt(centerCost) : null,
            docNumber: String(docNumber).length > 0 ? docNumber : null,
            comments: String(comments).length > 0 ? comments : null,
            barCode: String(barCode).length > 0 ? barCode : null,
            dueDate: allInstallments[indexSubmit].date,
            payment: allInstallments[indexSubmit].typePayment,
            beneficiaryType,
            supplier:
              beneficiaryType === "supplier" ? beneficiaryId || null : null,
            seller: beneficiaryType === "seller" ? beneficiaryId || null : null,
            issueDate: String(issueDate).length > 0 ? issueDate : null,
            occurrence: "parcelada",
            categoryName:
              String(dataForm.categoryName).length > 0
                ? dataForm.categoryName
                : null,
            dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
            payedDate: isPaid ? today : null,
            totalPaid: isPaid ? allInstallments[indexSubmit].value : null,
            attachments: JSON.stringify(filesPath),
            companyId: selectedCompany,
          };

          const parcResult = await api.post("billsToPay", data);

          LogService.logRegister({
            itemId: parcResult.data.id,
            module: "Contas a Pagar",
            itemName: parcResult.data.name,
          });
        }
      } else {
        const data = {
          name: name,
          amount: formatToFloat(amount),
          remaining: formatToFloat(amount),
          status: isPaid ? "paid" : "pending",
          bankAccount:
            String(accountBank).length > 0 ? parseInt(accountBank) : null,
          nameBank: String(accountBank).length > 0 ? bank.data.nameBank : null,
          centerCost:
            String(centerCost).length > 0 ? parseInt(centerCost) : null,
          docNumber: String(docNumber).length > 0 ? docNumber : null,
          comments: String(comments).length > 0 ? comments : null,
          barCode: String(barCode).length > 0 ? barCode : null,
          dueDate: String(dueDate).length > 0 ? dueDate : null,
          beneficiaryType,
          payment: String(payment).length > 0 ? payment : null,
          supplier:
            beneficiaryType === "supplier" ? beneficiaryId || null : null,
          seller: beneficiaryType === "seller" ? beneficiaryId || null : null,
          issueDate: String(issueDate).length > 0 ? issueDate : null,
          occurrence: "unica",
          categoryName:
            String(dataForm.categoryName).length > 0
              ? dataForm.categoryName
              : null,
          dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
          payedDate: isPaid ? today : null,
          totalPaid: isPaid ? formatToFloat(amount) : null,
          attachments: JSON.stringify(filesPath),
          companyId: selectedCompany,
        };

        const unicResult = await api.post("billsToPay", data);

        LogService.logRegister({
          itemId: unicResult.data.id,
          module: "Contas a Pagar",
          itemName: unicResult.data.name + " - Unitário",
        });
      }

      setIsSubmit(false);

      history.push("/contas-a-pagar");
    } catch (error) {
      setIsSubmit(false);
      console.log(error.message);
    }
  };

  function inputsVerify(dataForm: any) {
    setExpensesError(false);
    setAccountBankError(false);
    setPayMethodsError(false);
    setDueError(false);
    setValueError(false);
    setAccountPlanError(false);
    setIssueDateError(false);

    if (!name) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Nome da despesa é obrigatório!");
      setShowModalError(true);
      setExpensesError(true);
      return false;
    }

    if (!accountBank) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Selecione uma conta bancária!");
      setShowModalError(true);
      return false;
    }

    if (!selectedCompany) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Selecione uma empresa!");
      setShowModalError(true);
      return false;
    }

    if (!payment) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Forma de Pagamento é obrigatório!");
      setShowModalError(true);
      setPayMethodsError(true);
      return false;
    }

    if (!dreSubCategoryId) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Plano de Contas é obrigatório!");
      setShowModalError(true);
      setAccountPlanError(true);
      return false;
    }

    if (!dueDate) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Vencimento é obrigatório!");
      setShowModalError(true);
      setDueError(true);
      return false;
    }

    if (!amount) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Valor é obrigatório!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }
    if (Number(amount) <= 0) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Valor deve ser maior que zero!");
      setShowModalError(true);
      setValueError(true);
      return false;
    }

    if (!issueDate) {
      setActiveTab("detalhes-despesa");
      setIsSubmit(false);
      setMsgError("Campo Data de Emissão é obrigatório!");
      setShowModalError(true);
      setIssueDateError(true);
      return false;
    }

    if (issueDate) {
      const dateIsValid = dateIsBetweenRange(
        issueDate,
        "",
        extractDateStringFromTimestamp()
      );
      if (!dateIsValid) {
        setActiveTab("detalhes-despesa");
        setIsSubmit(false);
        setMsgError("A data de emissão não pode ser maior que a data atual!");
        setShowModalError(true);
        setIssueDateError(true);
        return false;
      }
    }

    return true;
  }

  function changeOccurrence(type: any) {
    if (type == "parcelada") {
      setIsInstallment(true);
    } else {
      setIsInstallment(false);
    }
  }

  function convertToFloat(value: any) {
    var valueConverted = value.includes("R$") ? value.replace("R$", "") : value;
    if (valueConverted.includes(".") && valueConverted.includes(",")) {
      valueConverted = valueConverted.replace(".", "").replace(",", ".");
    } else {
      valueConverted = valueConverted.replace(",", ".");
    }

    return parseFloat(valueConverted);
  }

  function generateInstallment() {
    if (occurrence == "parcelada") {
      if (amount && amount != "0") {
        var allInstallments = [];
        var countInstallments = watch("installments");
        var valueExpense = convertToFloat(amount);
        var resultDivided = valueExpense / watch("installments");
        var currentDate = new Date();

        for (var index = 0; index < countInstallments; index++) {
          var month = currentDate.getMonth() + 1;

          allInstallments.push({
            date:
              currentDate.getFullYear() +
              "-" +
              (month < 10 ? "0" + month : month) +
              "-" +
              currentDate.getDate(),
            value: resultDivided,
            typePayment: "Boleto",
          });

          currentDate.setMonth(currentDate.getMonth() + 1);
        }

        setInstallments({ installments: allInstallments });
        setIsGenerateInstallment(true);
      } else {
        setMsgError("Informe o valor da despesa para gerar parcelas!");
        setShowModalError(true);
        setInstallments({ installments: [] });
        setIsGenerateInstallment(false);
      }
    } else {
      setInstallments({ installments: [] });
      setIsGenerateInstallment(false);
    }
  }

  function distributeInstallments(value: any, index: any) {
    var allInstallments = installments.installments;

    if (index == 0) {
      for (
        var indexInstallment = 0;
        indexInstallment < allInstallments.length;
        indexInstallment++
      ) {
        if (indexInstallment > index) {
          var distributed =
            Math.abs(value - convertToFloat(amount)) /
            (allInstallments.length - 1);

          allInstallments[indexInstallment].value = distributed;
        }
      }
    } else {
      var totalSumBeforeInstallments = 0;
      var countInstallments = allInstallments.length;

      for (
        var indexInstallment = 0;
        indexInstallment < allInstallments.length;
        indexInstallment++
      ) {
        if (indexInstallment == index) {
          var distributed = Math.abs(
            totalSumBeforeInstallments - convertToFloat(amount)
          );

          if (allInstallments[indexInstallment].value > distributed) {
            allInstallments[indexInstallment].value =
              distributed / countInstallments;
            setMsgError("O valor ultrapassou o limite da soma das parcelas!");
            setShowModalError(true);
          } else {
            totalSumBeforeInstallments +=
              allInstallments[indexInstallment].value;
            countInstallments--;
          }
        }

        if (indexInstallment < index) {
          totalSumBeforeInstallments += allInstallments[indexInstallment].value;
          countInstallments--;
        } else if (indexInstallment > index) {
          var distributed =
            Math.abs(totalSumBeforeInstallments - convertToFloat(amount)) /
            countInstallments;

          allInstallments[indexInstallment].value = distributed;
        }
      }
    }

    setInstallments({ installments: allInstallments });
  }

  function handleChangeBeneficiaryType(value: string) {
    setBeneficiaryType(value);
    setBeneficiaryId("");
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <AddAccountBankModal
        showModal={showModalAddAccountBank}
        setShowModal={setShowModalAddAccountBank}
        onCreateAccountBank={handleCreateAccountBank}
      />

      <NewCustomerModal
        showModal={showModalNewCustomer}
        setShowModal={setShowModalNewCustomer}
        onCreateCustomer={handleCreateCustomer}
        defaultData={newCustomerDefaultData}
      />

      <form
        className={"makeStyles-container-12"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="newproduct-form-tabs"
        >
          <Tab eventKey="detalhes-despesa" title="Detalhes da Despesa">
            <div className="row">
              <div className="col-lg-6 mt-6">
                <CompanySelect
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <TextField
                  label="Nome da despesa*"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={expensesError}
                  className={classes.error}
                  value={name}
                  onChange={(evt) => {
                    setName(evt.target.value);
                    setExpensesError(evt.target.value ? false : true);
                  }}
                />
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Conta bancária *"
                  getOptionLabel={(option: BankAccount) =>
                    `${option.nameBank} - ${option.name}`
                  }
                  value={accountBank}
                  hasError={accountBankError}
                  onSelect={(option) =>
                    setAccountBank(String(option?.id ?? ""))
                  }
                  apiSearchHandler={(typedText) =>
                    BankAccountService.getBankAccountsFiltered({
                      name: typedText,
                      situation: "y",
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!accountBank) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(accountBank)
                      ) ?? BankAccountService.getBankAccountById(accountBank)
                    );
                  }}
                  onChangeTextField={(e) =>
                    setAccountBankError(e.target.value ? false : true)
                  }
                  renderAddButton={
                    <ApiResourceSelectAddButton
                      label="Adicionar Conta Bancária"
                      onClick={() => setShowModalAddAccountBank(true)}
                    />
                  }
                />
              </div>

              <div className="col-lg-3">
                <TextField
                  select
                  label="Forma de Pagamento*"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu && classes.error,
                    },
                  }}
                  error={payMethodsError}
                  margin="normal"
                  size="small"
                  variant="outlined"
                  value={payment}
                  onChange={(evt) => {
                    setPayment(evt.target.value);
                    setPayMethodsError(evt.target.value ? false : true);
                  }}
                >
                  <MenuItem key="0" value="">
                    Selecione
                  </MenuItem>

                  {paymentOptions.map((payment, index) => (
                    <MenuItem key={index + 1} value={payment.value}>
                      {payment.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="col-lg-3">
                <ApiResourceSelect
                  label="Plano de Contas *"
                  getOptionLabel={(option: SubCategory) => option.name}
                  value={dreSubCategoryId}
                  hasError={accountPlanError}
                  onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                  apiSearchHandler={(typedText) =>
                    DreCategoryService.getDreSubCategoriesFiltered(
                      { name: typedText },
                      "expense"
                    )
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!dreSubCategoryId) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(dreSubCategoryId)
                      ) ??
                      DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                    );
                  }}
                  onChangeTextField={(e) =>
                    setAccountPlanError(e.target.value ? false : true)
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2">
                <TextField
                  value={beneficiaryType}
                  select
                  label="Tipo Beneficiário"
                  size="small"
                  className="ml-0"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => handleChangeBeneficiaryType(e.target.value)}
                >
                  <MenuItem key="0" value="supplier">
                    Fornecedor
                  </MenuItem>

                  <MenuItem key="1" value="seller">
                    Vendedor
                  </MenuItem>
                </TextField>
              </div>

              <div className="col-lg-4 d-flex align-items-center">
                <ApiResourceSelect
                  style={{ width: "100%" }}
                  label="Beneficiário"
                  getOptionLabel={(option: Beneficiary) =>
                    `${option.id} - ${option.name} - ${option?.cnpj ||
                      option?.cpf}`
                  }
                  value={beneficiaryId}
                  onSelect={(option) =>
                    setBeneficiaryId(option ? String(option.id) : "")
                  }
                  apiSearchHandler={
                    beneficiaryType === "supplier"
                      ? (typedText) =>
                          CustomerService.getCustomersFiltered({
                            name: typedText,
                            nameWithId: true,
                          })
                      : (typedText) =>
                          SellerService.getSellersFiltered({ name: typedText })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!beneficiaryId) return null;

                    const loadedBeneficiary = loadedOptions.find(
                      (option) => option.id === Number(beneficiaryId)
                    );
                    if (loadedBeneficiary) return loadedBeneficiary;

                    return beneficiaryType === "supplier"
                      ? CustomerService.getCustomerById(beneficiaryId)
                      : SellerService.getSellerById(beneficiaryId);
                  }}
                  renderAddButton={
                    beneficiaryType === "supplier"
                      ? (typedText) => (
                          <ApiResourceSelectAddButton
                            label="Adicionar Fornecedor"
                            onClick={() => handleClickAddCustomer(typedText)}
                          />
                        )
                      : undefined
                  }
                />
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Vencimento*"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={dueError}
                  value={dueDate}
                  onChange={(evt) => {
                    setDueDate(evt.target.value);
                    setDueError(evt.target.value ? false : true);
                  }}
                  className={classes.error}
                />
              </div>

              <div className="col-lg-2">
                <NumericFormat
                  label="Valor"
                  startAdornment="R$"
                  error={valueError}
                  className={classes.error}
                  value={amount}
                  onChange={(evt) => {
                    setAmount(evt.target.value);
                    setValueError(evt.target.value ? false : true);
                  }}
                />
              </div>

              <div className="col-lg-2">
                <TextField
                  type="date"
                  label="Data de emissão *"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={issueDateError}
                  value={issueDate}
                  onChange={(e) => setIssueDate(e.target.value)}
                />
              </div>

              <div className="col-lg-5">
                <TextField
                  label="Código de barras"
                  margin="normal"
                  variant="outlined"
                  size="small"
                  value={barCode}
                  onChange={(evt) => setBarCode(evt.target.value)}
                />
              </div>

              {occurrence == "unica" || !occurrence ? (
                <div className="col-lg-2 d-flex align-items-center">
                  <FormControlLabel
                    label="Pago"
                    className="ml-1"
                    checked={isPaid}
                    onChange={() => setIsPaid((state) => !state)}
                    control={<Checkbox color="primary" />}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Tab>

          <Tab eventKey="detalhes-conta" title="Detalhes da Conta">
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  label="Nº do documento"
                  size="small"
                  className="ml-0"
                  margin="normal"
                  variant="outlined"
                  value={docNumber}
                  onChange={(evt) => setDocNumber(evt.target.value)}
                />
              </div>

              <div className="col-lg-4">
                <ApiResourceSelect
                  label="Centro de Custos"
                  getOptionLabel={(option: CenterCost) => option.name}
                  value={centerCost}
                  onSelect={(option) =>
                    setCenterCost(option ? String(option.id) : "")
                  }
                  apiSearchHandler={(typedText) =>
                    CenterCostService.getCenterCostsFiltered({
                      name: typedText,
                    })
                  }
                  getSelectedOption={(loadedOptions) => {
                    if (!centerCost) return null;
                    return (
                      loadedOptions.find(
                        (option) => option.id === Number(centerCost)
                      ) ?? CenterCostService.getCenterCostById(centerCost)
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <TextField
                  value={occurrence}
                  select
                  label="Recorrência"
                  size="small"
                  className="ml-0"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setOccurrence(e.target.value);
                    changeOccurrence(e.target.value);
                  }}
                >
                  <MenuItem key="0" value="unica">
                    Única
                  </MenuItem>

                  <MenuItem key="1" value="parcelada">
                    Parcelada
                  </MenuItem>
                </TextField>
              </div>

              {isInstallment ? (
                <div className="col-lg-2 d-flex align-items-center">
                  <TextField
                    {...register("installments")}
                    size="small"
                    type="number"
                    label="QTD Parcelas"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 1 } }}
                  />

                  <Button
                    type="button"
                    variant="primary"
                    className="mt-2 ml-3"
                    onClick={() => generateInstallment()}
                  >
                    Gerar
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>

            {isGenerateInstallment ? (
              <div className="mt-3 ml-20 mb-3 border-bottom">
                {installments.installments.map((installmentData, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col-lg-3">
                        <TextField
                          size="small"
                          type="date"
                          label="Data"
                          margin="normal"
                          variant="outlined"
                          value={installmentData.date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].date = e.target.value;
                            setInstallments({ installments: allInstallments });
                          }}
                        />
                      </div>

                      <div className="col-lg-3">
                        <NumericFormat
                          label="Valor"
                          startAdornment="R$"
                          value={installmentData.value}
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].value = convertToFloat(
                              e.target.value
                            );
                            setInstallments({ installments: allInstallments });
                            distributeInstallments(
                              convertToFloat(e.target.value),
                              index
                            );
                          }}
                          disabled={
                            index + 1 == installments.installments.length
                              ? true
                              : false
                          }
                        />
                      </div>

                      <div className="col-lg-3">
                        <TextField
                          select
                          key={index}
                          value={installmentData.typePayment}
                          label="Forma de Pagamento*"
                          SelectProps={{
                            MenuProps: {
                              className: `${classes.menu} ${classes.error}`,
                            },
                          }}
                          margin="normal"
                          size="small"
                          variant="outlined"
                          error={payMethodsError}
                          onChange={(e) => {
                            var allInstallments = installments.installments;
                            allInstallments[index].typePayment = e.target.value;
                            setInstallments({ installments: allInstallments });
                            setPayMethodsError(e.target.value ? false : true);
                          }}
                        >
                          <MenuItem key="0" value="">
                            Selecione
                          </MenuItem>

                          {paymentOptions.map((payment, index) => (
                            <MenuItem key={index + 1} value={payment.value}>
                              {payment.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-lg-5">
                <UploadFiles
                  label="Anexos"
                  filesSelected={filesSelected}
                  setFilesSelected={setFilesSelected}
                  filesNamesSaved={filesNamesSaved}
                  setFilesNamesSaved={setFilesNamesSaved}
                />
              </div>
            </div>

            <TextField
              label="Observações"
              multiline
              rows="2"
              size="small"
              className="ml-0"
              margin="normal"
              variant="outlined"
              value={comments}
              onChange={(evt) => setComments(evt.target.value)}
            />
          </Tab>
        </Tabs>

        <div className="col-lg-6 mt-15 d-flex flex-row">
          <Button
            type="submit"
            className="mr-3"
            variant="primary"
            disabled={isSubmit}
          >
            {isSubmit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />

                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <>
                <span>Salvar</span>
              </>
            )}
          </Button>

          <Link href="/contas-a-pagar" className="btn btn-secondary">
            Cancelar
          </Link>
        </div>
      </form>
    </div>
  );
}
